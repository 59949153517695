import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../config/axios';
import DialogBasic from '../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../utility/validation';
import Errormsg from "../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../util/IntlMessages";
import { TagFaces } from '@material-ui/icons';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbFulltimerTargetEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          multiplier: 0
        },
        error:{
          multiplier: ""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
    };

  }

  componentDidMount() {
    this.getTarget();
  }


  getTarget() {
    let outer = this;
    axios.post('bo/int/deftargetconv', {}).then(({data})=>{
      if(data.sta && data.stacod === 200){
        console.log("data target", data.data)
        let multiplier = data.data.multiplier;
        outer.setState({
          form: {
            multiplier: multiplier
            // baptis: targ.baptis
          }
        })
      } else {
        outer.showDialog("Warning", data.msg);
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  
  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  changeFocus = (e) => {
    let outer = this;
    let {form, error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if(inputValue.trim() === ''){
      form[inputName] = 0;
      this.setState({form});
    }
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if(inputValue.trim() != ''){
      const re = /^[0-9.]+$/;
      // const re = /^\$[0-9]+(\.[0-9][0-9])?$/;
      if (inputValue == '' || re.test(inputValue)) {
        form[inputName] = inputValue;
        this.setState({form});
      }
    }else{
      form[inputName] = '';
      this.setState({form});
    }
  }

  handleValidation = (e) => {
    let {form,error,mode} = this.state;

    let errmultiplier = '';
    if(form.multiplier <= 0){
      errmultiplier="Rumus harus lebih besar dari 0";
    }else{
      errmultiplier="";
    }

    if(errmultiplier == ""){
      if(parseFloat(form.multiplier) == NaN){
        errmultiplier="Rumus harus berupa angka";
      }else{
        errmultiplier="";
      }
    }

    this.setState({
      form:form,
      error:{
        ...error,
        multiplier: errmultiplier
      }
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;
    const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
          
          axios.post('bo/int/deftargetconv/edit', {multiplier: parseFloat(form.multiplier)}
          ).then(({data}) => {
            if (data.sta && data.stacod === 200) {
              outer.setState({
                disableBtnSubmit:false
              }, () => {
                outer.showDialog('Information',<IntlMessages id="setting.defaultmultiplier.success.edit"/>);
                outer.getTarget();
              })
              
            } else {
              outer.setState({
                disableBtnSubmit:false
              });
              outer.showDialog('Warning',data.msg);
            }
          }).catch(function (error) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',Errormsg["500"]);
          });
      });

  }

  

  isValidForm = () => {
    return (
    !this.state.error.multiplier)
  }

  

 

  render(){
    const { classes, theme } = this.props;
    const { error, fttypes, fulltimers } = this.state;
    const title = "Profile"
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Setting Default Konversi Target Jam ke Orang
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>

                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      *Note: Rumus diisi dengan total pengali konversi target waktu ke target orang. <br/>
                      Misal: Rumus default adalah 3/2, jadi rumus bisa diisi dengan 1.5
                    </Grid> 
                    <Grid item xs={12}>
                      <TextField
                        id="multiplier"
                        name="multiplier"
                        label={<IntlMessages id="setting.defaultmultiplier.multiplier"/>}
                        value={this.state.form.multiplier}
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        onBlur={this.changeFocus}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {
                        error.multiplier &&   
                          <FormControlLabel
                            classes={{label:classes.labelerror}}
                            control={<div>&nbsp;&nbsp;&nbsp;</div>}
                            label={error.multiplier}
                          />
                      }
                    </Grid>
      
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    UPDATE
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbFulltimerTargetEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbFulltimerTargetEdit)));