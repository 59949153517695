import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Checkbox, Card, FormControl,
  CardMedia, CardContent, Typography, Select, InputLabel, MenuItem, Collapse, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DataTable from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";
import { isThisSecond } from 'date-fns';

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const tableStyle = {
  table: {
    borderCollapse: "collapse",
    width: 275
  },
  body: {
    border: 1,
    borderStyle: "solid",
    borderColor: "#dddddd",
    textAlign: 'center',
    padding: 8
  }
}

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbReportNonPenggembalaanWeek extends React.Component {
  constructor(props) {
    console.log("window inner width", window.innerWidth)
    super(props);
    let dat = new Date();
    dat.setDate(dat.getDate() - 7);
    this.state = {
      setdate: "",
      page: {
        city: "",
        date: moment().format('YYYY-MM-DD'),
        datePar: new Date(),
        page: 1,
        count: 100,
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      canPreviousPage: true,
      canNextPage: false,
      dataTotal: 11,
      totalPage: 1,
      locallist: [],
      selectedLocal: {},
      startWeek: "",
      endWeek: "",
      nonshepList: [],
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
      window: 0
    }
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getNonshReport();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getNonshReport();
    })
  }

  componentDidMount() {
    this.getNonshReport();
    let startWeekMoment = moment(this.state.page.date).subtract(1, 'days').startOf('week');
    let endWeekMoment = moment(this.state.page.date).subtract(1, 'days').endOf('week');
    this.setState({
      startWeek: moment(startWeekMoment).add(1, 'days').format('DD MMM YYYY'),
      endWeek: moment(endWeekMoment).add(1, 'days').format('DD MMM YYYY')
    });
    this.getLocal();
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        page: {
          ...this.state.page,
          lokalid: val.id
        }
      }, () => { outer.getNonshReport() });
    } else {
      this.setState({
        selectedLocal: {},
        page: {
          ...this.state.page,
          lokalid: "",
        }
      }, () => { outer.getNonshReport() });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  goToPage(val) {
    let outer = this;
    let checkNext = false;
    let checkPrev = false;
    if (val === this.state.totalPage) {
      checkNext = true;
    }
    if (val === 1) {
      checkPrev = true;
    }
    this.setState({
      page: {
        ...this.state.page,
        page: val,
      },
      canNextPage: checkNext,
      canPreviousPage: checkPrev,
    }, () => { outer.getNonshReport() });
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      this.setState({ dialogOpen: false });
    }
  }


  getNonshReport = () => {
    let outer = this;
    let url = 'bo/int/report/nonshepherding/week';
    if (this.props.token.token.role == "Penatua") {
      url = 'bo/penatua/report/nonshepherding/week';
    };
    axios.post(url, outer.state.page).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        this.setState({
          nonshepList: data.data.list,
          dataTotal: data.data.total,
          totalPage: Math.ceil(data.data.total / this.state.page.count),
        })
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }



  changeDate = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    let startWeekMoment = moment(momentParse).subtract(1, 'days').startOf('week');
    let endWeekMoment = moment(momentParse).subtract(1, 'days').endOf('week');
    this.setState({
      page: {
        ...this.state.page,
        datePar: date,
        date: momentParse,
      },
      startWeek: moment(startWeekMoment).add(1, 'days').format('DD MMM YYYY'),
      endWeek: moment(endWeekMoment).add(1, 'days').format('DD MMM YYYY')
    }, () => {
      this.getNonshReport();
    })
  }

  changeCity = (val) => {
    if (val) {
      this.setState({
        page: {
          ...this.state.page,
          city: val.target.value
        }
      }, () => {
        this.getNonshReport();
      })
    }
  }

  changeCount= (val)=>{
    let dis=false;
    if(this.state.dataTotal<=val){
      dis=true;
    }
    this.setState({
      page: {
        ...this.state.page,
        page:1,
        count: Number(val),
      },
      totalPage: Math.ceil(this.state.dataTotal / val),      
      canNextPage:dis,
      canPreviousPage:true,
    }, () => {
      this.getNonshReport();
    });
  }

  render() {
    const { theme } = this.props;
    const { nonshepList, startWeek, endWeek, locallist, page, totalPage, canPreviousPage, canNextPage } = this.state;
    return (
      <div>
        <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
          <div style={{ paddingTop: 10 }}>
            <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Laporan Mingguan
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/report/nonshepherdweek/list'>
              Non Penggembalaan Mingguan {startWeek + " - " + endWeek}
            </Link>            
          </Breadcrumbs>
          </div>
          <div className="filter">
            {/* <div>
              <FormControl
                style={{
                  marginBottom: 10
                }}
              >
                <TextField 
                  id="outlined-basic" 
                  label="Kota" 
                  variant="outlined" 
                  size="small" 
                  // style={{ margin: '5px' }}
                  onChange={(val) => this.changeCity(val)} 
                />
              </FormControl>
            </div> */}
            <div style={{ marginBottom: 10 }}>
              <Autocomplete
                options={locallist}
                getOptionLabel={option => option.name}
                value={this.state.selectedLocal}
                onChange={this.changeLocal}
                onFocus={(e)=>{this.inputChangeLocal('')}}
                onInputChange={(event, val) => this.inputChangeLocal(val)}
                renderInput={
                  param => (
                    <TextField
                      {...param}
                      id="outlined-basic"
                      // variant="outlined" 
                      label="Lokal"
                      size="small"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )
                }
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
              <KeyboardDatePicker
                clearable
                value={this.state.page.datePar}
                // placeholder="Tanggal"
                label="Tanggal"
                inputVariant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={date => this.changeDate(date)}
                format="DD-MM-YYYY"
              />
            </div>
            <div>
              <Typography variant="subtitle2">
                * Keterangan Persentase:
              </Typography>
              <div>
                <span style={{ color: 'red' }}>0</span> / <span style={{ color: 'pink' }}>0-25%</span> / <span style={{ color: 'orange' }}>26%-50%</span> /&nbsp;
                <span style={{ color: 'green' }}>51%-75%</span> / <span style={{ color: 'blue' }}>76%-100%</span> / <span style={{ color: 'black' }}>&gt;100%</span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div style={{ resize: 'both', marginTop: 10, marginBottom: 40, marginRight: 40 }}>
          {
            nonshepList.length > 0 &&
            <table style={tableStyle.table}>
              <tr>
                <th
                  style={tableStyle.body}
                  rowSpan={2}
                >Kota</th>
                <th
                  style={tableStyle.body}
                  rowSpan={2}
                >Username</th>
                <th
                  style={tableStyle.body}
                  colSpan={5}
                >Korporat</th>
                <th
                  style={tableStyle.body}
                  colSpan={5}
                >Lewi Hall</th>
                <th
                  style={tableStyle.body}
                  colSpan={5}
                >Lewi KS</th>
                <th
                  style={tableStyle.body}
                  colSpan={5}
                >Transportasi</th>
                {/* <th
                  style={tableStyle.body}
                  colSpan={5} 
                >Kolam</th>
                <th
                  style={tableStyle.body}
                  colSpan={5} 
                >Pelayanan Khusus</th> */}
                <th
                  style={tableStyle.body}
                  colSpan={5}
                >Lainnya</th>
                <th
                  style={tableStyle.body}
                  colSpan={5}
                >Penuntutan</th>
              </tr>
              <tr>
                <td style={tableStyle.body}>
                  Target
                </td>
                <td style={tableStyle.body}>
                  Realisasi
                </td>
                <td style={tableStyle.body}>
                  Persentase
                </td>
                <td style={tableStyle.body}>
                  Frekuensi
                </td>
                <td style={tableStyle.body}>
                  Efisiensi
                </td>
                <td style={tableStyle.body}>
                  Target
                </td>
                <td style={tableStyle.body}>
                  Realisasi
                </td>
                <td style={tableStyle.body}>
                  Persentase
                </td>
                <td style={tableStyle.body}>
                  Frekuensi
                </td>
                <td style={tableStyle.body}>
                  Efisiensi
                </td>
                <td style={tableStyle.body}>
                  Target
                </td>
                <td style={tableStyle.body}>
                  Realisasi
                </td>
                <td style={tableStyle.body}>
                  Persentase
                </td>
                <td style={tableStyle.body}>
                  Frekuensi
                </td>
                <td style={tableStyle.body}>
                  Efisiensi
                </td>
                <td style={tableStyle.body}>
                  Target
                </td>
                <td style={tableStyle.body}>
                  Realisasi
                </td>
                <td style={tableStyle.body}>
                  Persentase
                </td>
                <td style={tableStyle.body}>
                  Frekuensi
                </td>
                <td style={tableStyle.body}>
                  Efisiensi
                </td>
                <td style={tableStyle.body}>
                  Target
                </td>
                <td style={tableStyle.body}>
                  Realisasi
                </td>
                <td style={tableStyle.body}>
                  Persentase
                </td>
                <td style={tableStyle.body}>
                  Frekuensi
                </td>
                <td style={tableStyle.body}>
                  Efisiensi
                </td>
                <td style={tableStyle.body}>
                  Target
                </td>
                <td style={tableStyle.body}>
                  Realisasi
                </td>
                <td style={tableStyle.body}>
                  Persentase
                </td>
                <td style={tableStyle.body}>
                  Frekuensi
                </td>
                <td style={tableStyle.body}>
                  Efisiensi
                </td>
              </tr>
              {
                nonshepList.map((item, k) => {
                  return (
                    <tr key={k}>
                      <td
                        style={tableStyle.body}
                      >{item.city}</td>
                      <td
                        style={tableStyle.body}
                      >{item.name}</td>
                      <td
                        style={tableStyle.body}
                      >{item.korporat.target}</td>
                      <td
                        style={tableStyle.body}
                      >{item.korporat.realization}</td>
                      <td
                        style={tableStyle.body}
                      ><span
                        style={
                          item.korporat.percentnum == 0 ?
                            { color: 'red' } : item.korporat.percentnum > 0 && item.korporat.percentnum < 26 ? { color: "pink" } :
                              item.korporat.percentnum > 25 && item.korporat.percentnum < 51 ? { color: "orange" } : item.korporat.percentnum > 50 && item.korporat.percentnum < 76 ? { color: 'green' } :
                                item.korporat.percentnum > 75 && item.korporat.percentnum < 101 ? { color: "blue" } : item.korporat.percentnum > 100 && { color: "black" }
                        }
                      >{item.korporat.percent}</span></td>
                      <td
                        style={tableStyle.body}
                      >{item.korporat.freq}</td>
                      <td
                        style={tableStyle.body}
                      >{item.korporat.efficiency}</td>
                      <td
                        style={tableStyle.body}
                      >{item.lewihall.target}</td>
                      <td
                        style={tableStyle.body}
                      >{item.lewihall.realization}</td>
                      <td
                        style={tableStyle.body}
                      ><span
                        style={
                          item.lewihall.percentnum == 0 ?
                            { color: 'red' } : item.lewihall.percentnum > 0 && item.lewihall.percentnum < 26 ? { color: "pink" } :
                              item.lewihall.percentnum > 25 && item.lewihall.percentnum < 51 ? { color: "orange" } : item.lewihall.percentnum > 50 && item.lewihall.percentnum < 76 ? { color: 'green' } :
                                item.lewihall.percentnum > 75 && item.lewihall.percentnum < 101 ? { color: "blue" } : item.lewihall.percentnum > 100 && { color: "black" }
                        }
                      >{item.lewihall.percent}</span></td>
                      <td
                        style={tableStyle.body}
                      >{item.lewihall.freq}</td>
                      <td
                        style={tableStyle.body}
                      >{item.lewihall.efficiency}</td>
                      <td
                        style={tableStyle.body}
                      >{item.lewiks.target}</td>
                      <td
                        style={tableStyle.body}
                      >{item.lewiks.realization}</td>
                      <td
                        style={tableStyle.body}
                      ><span
                        style={
                          item.lewiks.percentnum == 0 ?
                            { color: 'red' } : item.lewiks.percentnum > 0 && item.lewiks.percentnum < 26 ? { color: "pink" } :
                              item.lewiks.percentnum > 25 && item.lewiks.percentnum < 51 ? { color: "orange" } : item.lewiks.percentnum > 50 && item.lewiks.percentnum < 76 ? { color: 'green' } :
                                item.lewiks.percentnum > 75 && item.lewiks.percentnum < 101 ? { color: "blue" } : item.lewiks.percentnum > 100 && { color: "black" }
                        }
                      >{item.lewiks.percent}</span></td>
                      <td
                        style={tableStyle.body}
                      >{item.lewiks.freq}</td>
                      <td
                        style={tableStyle.body}
                      >{item.lewiks.efficiency}</td>
                      <td
                        style={tableStyle.body}
                      >{item.transportasi.target}</td>
                      <td
                        style={tableStyle.body}
                      >{item.transportasi.realization}</td>
                      <td
                        style={tableStyle.body}
                      ><span
                        style={
                          item.transportasi.percentnum == 0 ?
                            { color: 'red' } : item.transportasi.percentnum > 0 && item.transportasi.percentnum < 26 ? { color: "pink" } :
                              item.transportasi.percentnum > 25 && item.transportasi.percentnum < 51 ? { color: "orange" } : item.transportasi.percentnum > 50 && item.transportasi.percentnum < 76 ? { color: 'green' } :
                                item.transportasi.percentnum > 75 && item.transportasi.percentnum < 101 ? { color: "blue" } : item.transportasi.percentnum > 100 && { color: "black" }
                        }
                      >{item.transportasi.percent}</span></td>
                      <td
                        style={tableStyle.body}
                      >{item.transportasi.freq}</td>
                      <td
                        style={tableStyle.body}
                      >{item.transportasi.efficiency}</td>
                      {/* <td
                        style={tableStyle.body}
                      >{item.kolam.target}</td>
                      <td
                        style={tableStyle.body}
                      >{item.kolam.realization}</td>
                      <td
                        style={tableStyle.body}
                      ><span
                      style={
                        item.kolam.percentnum == 0 ?
                        {color: 'red'} : item.kolam.percentnum > 0 && item.kolam.percentnum < 26 ? {color: "pink"} : 
                        item.kolam.percentnum > 25 && item.kolam.percentnum < 51 ? {color: "orange"} : item.kolam.percentnum > 50 && item.kolam.percentnum < 76 ? {color: 'green'} :
                        item.kolam.percentnum > 75 && item.kolam.percentnum < 101 ? {color: "blue"} : item.kolam.percentnum > 100 && {color: "black"}
                      }
                      >{item.kolam.percent}</span></td>
                      <td
                        style={tableStyle.body}
                      >{item.kolam.freq}</td>
                      <td
                        style={tableStyle.body}
                      >{item.kolam.efficiency}</td>
                      <td
                        style={tableStyle.body}
                      >{item.pelayanankhusus.target}</td>
                      <td
                        style={tableStyle.body}
                      >{item.pelayanankhusus.realization}</td>
                      <td
                        style={tableStyle.body}
                      ><span
                      style={
                        item.pelayanankhusus.percentnum == 0 ?
                        {color: 'red'} : item.pelayanankhusus.percentnum > 0 && item.pelayanankhusus.percentnum < 26 ? {color: "pink"} : 
                        item.pelayanankhusus.percentnum > 25 && item.pelayanankhusus.percentnum < 51 ? {color: "orange"} : item.pelayanankhusus.percentnum > 50 && item.pelayanankhusus.percentnum < 76 ? {color: 'green'} :
                        item.pelayanankhusus.percentnum > 75 && item.pelayanankhusus.percentnum < 101 ? {color: "blue"} : item.pelayanankhusus.percentnum > 100 && {color: "black"}
                      }
                      >{item.pelayanankhusus.percent}</span></td>
                      <td
                        style={tableStyle.body}
                      >{item.pelayanankhusus.freq}</td>
                      <td
                        style={tableStyle.body}
                      >{item.pelayanankhusus.efficiency}</td> */}
                      <td
                        style={tableStyle.body}
                      >{item.lainnya.target}</td>
                      <td
                        style={tableStyle.body}
                      >{item.lainnya.realization}</td>
                      <td
                        style={tableStyle.body}
                      ><span
                        style={
                          item.lainnya.percentnum == 0 ?
                            { color: 'red' } : item.lainnya.percentnum > 0 && item.lainnya.percentnum < 26 ? { color: "pink" } :
                              item.lainnya.percentnum > 25 && item.lainnya.percentnum < 51 ? { color: "orange" } : item.lainnya.percentnum > 50 && item.lainnya.percentnum < 76 ? { color: 'green' } :
                                item.lainnya.percentnum > 75 && item.lainnya.percentnum < 101 ? { color: "blue" } : item.lainnya.percentnum > 100 && { color: "black" }
                        }
                      >{item.lainnya.percent}</span></td>
                      <td
                        style={tableStyle.body}
                      >{item.lainnya.freq}</td>
                      <td
                        style={tableStyle.body}
                      >{item.lainnya.efficiency}</td>
                      <td
                        style={tableStyle.body}
                      >{item.penuntutan.target}</td>
                      <td
                        style={tableStyle.body}
                      >{item.penuntutan.realization}</td>
                      <td
                        style={tableStyle.body}
                      ><span
                        style={
                          item.penuntutan.percentnum == 0 ?
                            { color: 'red' } : item.penuntutan.percentnum > 0 && item.penuntutan.percentnum < 26 ? { color: "pink" } :
                              item.penuntutan.percentnum > 25 && item.penuntutan.percentnum < 51 ? { color: "orange" } : item.penuntutan.percentnum > 50 && item.penuntutan.percentnum < 76 ? { color: 'green' } :
                                item.penuntutan.percentnum > 75 && item.penuntutan.percentnum < 101 ? { color: "blue" } : item.penuntutan.percentnum > 100 && { color: "black" }
                        }
                      >{item.penuntutan.percent}</span></td>
                      <td
                        style={tableStyle.body}
                      >{item.penuntutan.freq}</td>
                      <td
                        style={tableStyle.body}
                      >{item.penuntutan.efficiency}</td>
                    </tr>

                  )
                })
              }
            </table>
          }
          {nonshepList.length > 0 &&
            <div className="pagination" style={{marginTop:10}}>
              <button onClick={() => this.goToPage(1)} disabled={canPreviousPage}>
                {'<<'}
              </button>{' '}
              <button onClick={() => this.goToPage(page.page - 1)} disabled={canPreviousPage}>
                {'<'}
              </button>{' '}
              <button onClick={() => this.goToPage(page.page + 1)} disabled={canNextPage}>
                {'>'}
              </button>{' '}
              <button onClick={() => this.goToPage(totalPage)} disabled={canNextPage}>
                {'>>'}
              </button>{' '}
              <span>
                Page{' '}
                <strong>
                  {page.page} of {totalPage}
                </strong>{' '}
              </span>
              <select
                value={page.count}
                onChange={e => {
                  this.changeCount(e.target.value);
                }}
              >
                {[100,250,500].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          }
        </div>
        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbReportNonPenggembalaanWeek.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportNonPenggembalaanWeek)));