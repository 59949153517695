import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import { ValidateEmail } from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";

const queryString = require('query-string');

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbMasterServiceTypeEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: "",
        name: "",
        desc: "",
        timeinput: "true",
        personinput: "false"

      },
      error: {
        id: "",
        name: "",
        desc: "",
        timeinput: "",
        personinput: ""
      },
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      mode: 'add',
      alowPeriod: [],
      filtertable: {},
    };
  }

  componentDidMount() {
    // this.loadData();
    let outer = this;
    // console.log("service ", this.props.param);
    let qparam = queryString.parse(this.props.location.search);
    let filter = this.props.location.state.filter;
    this.setState({ filtertable: filter });
    // console.log("qparam ",qparam);
    if (qparam && qparam.i) {
      if (this.props.param != null) {
        let serv = this.props.param.detail;
        this.setState({
          form: {
            id: serv.id,
            name: serv.name,
            desc: serv.desc,
            timeinput: serv.timeinput == "" ? "true" : serv.timeinput,
            personinput: serv.personinput == "" ? "false" : serv.personinput
          },
          mode: 'edit'
        })
      } else {
        //get detail
        this.getServiceDetail(qparam.i);
      }
    }
  }

  getServiceDetail = (id) => {
    let outer = this;
    axios.post('bo/int/master/servicetype', { id: id }).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        let serv = data.data;
        this.setState({
          form: {
            id: serv.id,
            name: serv.name,
            desc: serv.desc,
            timeinput: serv.timeinput == "" ? "true" : serv.timeinput,
            personinput: serv.personinput == "" ? "false" : serv.personinput
          },
          mode: 'edit'
        })
        // outer.setState({userList:data.data});
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      if (this.state.dialogTitle != "Information") {
        this.setState({ dialogOpen: false });
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          // this.props.history.push("/bo/master/servicetype/list");
          this.backToList();
          this.props.deselectParam();
        });

      }
    }
  }

  backToList=()=>{
    this.props.history.push({ pathname: "/bo/master/servicetype/list", filter:this.state.filtertable });
  }

  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  handleChange = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if (inputName === "accept") {
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if (newAccept === true) {
        error[inputName] = ""
      }
    }

    this.setState({
      form: form,
      error: error
    }, () => {
      switch (inputName) {
        case "mobile":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        default:
          break;
      }
    });
  }

  handleValidation = (e) => {
    let { form, error, mode } = this.state;

    if (form.name.trim().length === 0) {
      error.name = <IntlMessages id="master.servicetype.error.name" />;
    } else {
      error.name = "";
    }

    if (form.desc.trim().length === 0) {
      error.desc = <IntlMessages id="master.servicetype.error.desc" />;
    } else {
      error.desc = "";
    }

    if (form.timeinput.trim().length === 0) {
      error.timeinput = <IntlMessages id="master.servicetype.error.timeinput" />;
    } else {
      error.timeinput = "";
    }

    if (form.personinput.trim().length === 0) {
      error.personinput = <IntlMessages id="master.servicetype.error.personinput" />;
    } else {
      error.personinput = "";
    }



    if (!this.isValidForm()) {
      form.accept = false;
      error.accept = "";
    }

    this.setState({
      form: form,
      error: error
    }, () => {

      if (!this.isValidForm()) {
        return;
      }

      this.handleSubmit();

    });

  }

  handleSubmit = () => {
    let { form } = this.state;
    // const formData = new FormData();
    // Object.entries(form).map(([key,value]) => {
    //   if(value != undefined){
    //     switch (key) {
    //       case 'birthdate':            
    //         formData.append(key,formatDate(value));
    //         break;
    //       default:
    //         formData.append(key,value)
    //         break;
    //     }
    //   }else{
    //     formData.append(key,'')
    //   }
    // });


    if (this.state.mode == 'add') {

      const outer = this;
      this.setState({
        disableBtnSubmit: true
      }, () => {
        axios.post('bo/int/master/servicetype/add', form
        ).then(({ data }) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              form: {
                id: "",
                name: "",
                desc: "",
                timeinput: "true",
                personinput: "false"
              },
              error: {
                id: "",
                name: "",
                desc: "",
                timeinput: "",
                personinput: ""
              },
              disableBtnSubmit: false
            }, () => {
              outer.showDialog('Information', <IntlMessages id="master.servicetype.success.addservice" />);
            });

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      });

    } else {
      const outer = this;
      this.setState({
        disableBtnSubmit: true
      }, () => {
        axios.post('bo/int/master/servicetype/edit', form
        ).then(({ data }) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              form: {
                id: "",
                name: "",
                desc: "",
                timeinput: "true",
                personinput: "false"
              },
              error: {
                id: "",
                name: "",
                desc: "",
                timeinput: "",
                personinput: ""
              },
              disableBtnSubmit: false
            }, () => {
              outer.showDialog('Information', <IntlMessages id="master.servicetype.success.editservice" />);
            });

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      });

    }

  }

  isValidForm = () => {
    return (
      !this.state.error.name &&
      !this.state.error.desc &&
      !this.state.error.timeinput &&
      !this.state.error.personinput)
  }





  render() {
    const { classes, theme } = this.props;
    const { error, alowPeriod } = this.state;
    const title = "Profile"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="inherit" 
            // href="/bo/master/servicetype/list"
            href='#'
            onClick={()=>{this.backToList()}}
            >
              Daftar Tipe Pelayanan
            </Link>
            <Link color="textPrimary" href="/bo/master/servicetype/edit">
              {
                this.state.mode == "add" ?
                  "Tambah Tipe Pelayanan" : "Ubah Tipe Pelayanan"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <image src={this.state.previewProfile} style={{ width: 100 }} />
            <form className={classes.form} noValidate>


              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    label={<IntlMessages id="master.servicetype.name" />}
                    value={this.state.form.name}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    // disabled={this.state.mode == 'edit'}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.name ? true : false}
                  />
                  {
                    error.name &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.name}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="desc"
                    name="desc"
                    label={<IntlMessages id="master.servicetype.desc" />}
                    value={this.state.form.desc}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.desc ? true : false}
                  />
                  {
                    error.desc &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.desc}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset" className={classes.formControl} style={{ width: '100%' }}>
                    <FormLabel component="legend" error={error.timeinput ? true : false} style={{ fontSize: 12 }}><IntlMessages id="master.servicetype.timeinput" />*</FormLabel>
                    <RadioGroup aria-label="timeinput" id="timeinput" name="timeinput" value={this.state.form.timeinput} onChange={this.handleChange}>
                      <FormControlLabel value="true" control={<Radio />} label={"Ya"} />
                      <FormControlLabel value="false" control={<Radio />} label={"Tidak"} />
                    </RadioGroup>
                    {
                      error.timeinput &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.timeinput}
                      />
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" className={classes.formControl} style={{ width: '100%' }}>
                    <FormLabel component="legend" error={error.personinput ? true : false} style={{ fontSize: 12 }}><IntlMessages id="master.servicetype.personinput" />*</FormLabel>
                    <RadioGroup aria-label="personinput" id="personinput" name="personinput" value={this.state.form.personinput} onChange={this.handleChange}>
                      <FormControlLabel value="true" control={<Radio />} label={"Ya"} />
                      <FormControlLabel value="false" control={<Radio />} label={"Tidak"} />
                    </RadioGroup>
                    {
                      error.personinput &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.personinput}
                      />
                    }
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                UPDATE
              </Button>
            </form>
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbMasterServiceTypeEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  // console.log("state", state)
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, { deselectParam }
)(withStyles(styles)(withTheme(DbMasterServiceTypeEdit)));