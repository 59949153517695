import React from 'react';
import {
  CssBaseline, Grid, Container,
  Button, Dialog, DialogContent, DialogActions
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider, KeyboardTimePicker
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";
import moment from 'moment';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

class DbScheduleEditActive extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          id: "",
          day: "",
          dayname: "",
          timestart:"",
          timestartstr: null,
          timeend:"",
          timeendstr: null,
          title: "",
          description: "",
          servicetypename: "",
          servicetype: ""
          // desc:""
          
        },
        error:{
          day: "",
          timestart:"",
          timeend:"",
          title: "",
          description: "",
          servicetypename: "",
          servicetype: ""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        stpage: {
          keyword: "",
          count: 10
        },
        servicetypes:[],
        stobjects: null
    };
  }

  componentDidUpdate(prevprops) {
  
    if(prevprops.selected != this.props.selected) {
      let outer = this;
      if(isEmpty(this.props.selected) != true) {
        this.setState({
          form: {
            ...this.state.form,
            id: this.props.selected.id,
            day: this.props.selected.day,
            dayname: this.props.selected.dayname,
            timestart: moment(this.props.selected.timestart.replace('Z', '')).format("HH:mm"),
            timestartstr: this.props.selected.startTime,
            timeend: moment(this.props.selected.timeend.replace("Z", "")).format("HH:mm"),
            timeendstr: this.props.selected.endTime,
            title: this.props.selected.name,
            description: this.props.selected.description,
            servicetypename: this.props.selected.servicetypename,
            servicetype: this.props.selected.servicetypeid
          },
          mode: "edit"
        })
      }
    }
  }
  

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }


  

  closeDialog() {
    this.setState({
      form: {
        id: "",
        day: "",
        timestart:"",
        timestartstr: null,
        timeend:"",
        timeendstr: null,
        title: "",
        description: "",
        servicetypename: "",
        servicetype: ""
        // desc:""
        
      },
      error:{
        day: "",
        timestart:"",
        timeend:"",
        title: "",
        description: "",
        servicetypename: "",
        servicetype: ""
      },
      stobjects: null,
      mode: "add"
    });
    this.props.closeModal();
  }

 

  render(){
    const { classes, theme, openEdit } = this.props;
    const { error, servicetypes } = this.state;
    const title = "Profile"
    return (
      <Dialog
        open={openEdit}
        maxWidth={"sm"}
        fullWidth
        onClose={() => this.props.closeModal()}
        
      >
        <DialogContent>
        
          <Container component="main">
            <CssBaseline />
            
            <div className={classes.paper}>

                    <Grid container spacing={3}>
                      <Grid item xs={3} sm={3} md={3}>
                        <IntlMessages id="fulltimer.schedule.day"/>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                        {this.state.form.dayname}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <IntlMessages id="fulltimer.schedule.timestart"/>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                        {this.state.form.timestart}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                       <IntlMessages id="fulltimer.schedule.timeend"/>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                        {this.state.form.timeend}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                       <IntlMessages id="fulltimer.schedule.title"/>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                        {this.state.form.title}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <IntlMessages id="fulltimer.schedule.description"/>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                       {this.state.form.description}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <IntlMessages id="fulltimer.schedule.servicetype"/>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                       {this.state.form.servicetypename}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <IntlMessages id="fulltimer.schedule.subservicetype"/>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                       {this.state.form.subservicetypename}
                      </Grid>
                    </Grid>
            </div>
          </Container>
        </DialogContent> 
        <DialogActions>
          <Button onClick={() => this.closeDialog()} color="primary">
            Tutup
          </Button>
        </DialogActions>
         
      </Dialog>
    );
  }
}

DbScheduleEditActive.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  console.log("state",state)
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbScheduleEditActive)));