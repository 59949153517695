import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../config/axios';
import DialogBasic from '../../../../component/dialogbasic';
import { ValidateEmail } from '../../../../utility/validation';
import Errormsg from "../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { addParam, deselectParam } from "../../../../store/actions/configAction";
import moment from 'moment';
import queryString from 'query-string';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../util/IntlMessages";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbExportDailyEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        date: "",
        lokal: "",
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      locallist: [],
      selectedLocal: {},
      date: new Date(),
      code: "",
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      mode: 'add',
    };
  }

  componentDidMount() {
    const UrlQueryStrings = this.props.location.search;
    const queryValues = queryString.parse(UrlQueryStrings);
    console.log("query string", queryValues);
    let authcode = '';
    if (queryValues && queryValues.code) {
      authcode = queryValues.code;
    }
    const export_date = sessionStorage.getItem("export_date");
    console.log("export_date", export_date, authcode);
    let outer = this;
    if (export_date != null) {
      this.setState({
        date: new Date(export_date),
        code: authcode
      }, () => {
        if (authcode != '') {
          outer.handleTokenSubmit();
        }
      });
    }
    this.getLocal();
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
      }, () => { });
    } else {
      this.setState({
        selectedLocal: {},
      }, () => { });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      if (this.state.dialogTitle != "Information") {
        this.setState({ dialogOpen: false });
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          this.props.history.push('/bo/master/type/list');
          this.props.onDeselectParam();
        })
      }
    }
  }

  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  changeFocus = (e) => {
    let { form } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if (inputValue == "") {
      form[inputName] = 0;
    } else {
      form[inputName] = inputValue;
    }

    this.setState({
      form: form
    })
  }

  handleValidation = (e) => {
    let { date, error, mode, selectedLocal } = this.state;

    if (date == null) {
      error.date = <IntlMessages id="export.daily.error.date" />;
    } else {
      error.date = "";
    }

    // if (selectedLocal === {}) {
    //   error.lokal = "Harap isi lokal terlebih dahulu";
    // }

    // if(form.desc.trim().length === 0){
    //   error.desc=<IntlMessages id="master.type.error.desc"/>;
    // }else{
    //   error.desc="";
    // }

    // if(form.married.trim().length === 0){
    //   error.married=<IntlMessages id="master.user.error.married"/>;
    // }else{
    //   error.married="";
    // }


    if (!this.isValidForm()) {
      error.accept = "";
    }

    this.setState({
      error: error
    }, () => {

      if (!this.isValidForm()||error.lokal!=="") {
        return;
      }

      this.handleSubmit();

    });

  }

  handleTokenSubmit = () => {
    let { date, code, selectedLocal } = this.state;
    let parseDate = moment(date).format("YYYY-MM-DD");
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.get('bo/int/excel/daily/gtoken', {
        params: {
          date: parseDate,
          code: code,
        },
        responseType: 'blob'
      }
      ).then(({ data, headers }) => {
        if (headers && headers["content-type"] == 'application/octet-stream') {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'daily_report-' + moment(date).format("DD-MM-YYYY") + '_' + selectedLocal.name +'.xlsx'); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          outer.setState({
            disableBtnSubmit: false
          });
        } else {
          const fr = new FileReader();

          fr.addEventListener('loadend', (e) => {
            const dataJSON = e.srcElement.result;
            let datares = JSON.parse(dataJSON);
            if (datares.sta && datares.stacod === 200) {

            } else {
              outer.setState({
                disableBtnSubmit: false
              });
              outer.showDialog('Warning', datares.msg);
            }
          });

          fr.readAsText(data);
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"]);
      });
    });

  }

  handleSubmit = () => {
    let { date, selectedLocal } = this.state;
    let parseDate = moment(date).format("YYYY-MM-DD");
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.get('bo/int/excel/daily', {
        params: {
          date: parseDate,
          lokalid: selectedLocal.id,
        },
        responseType: 'blob'
      }
      ).then((response) => {
        let headers = response.headers;
        let data = response.data;
        console.log("header", headers, response.data);
        if (headers && headers["content-type"] == 'application/octet-stream') {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'daily_report_' + moment(date).format("DD-MM-YYYY") +'.xlsx'); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          outer.setState({
            disableBtnSubmit: false
          });
        } else {
          const fr = new FileReader();

          fr.addEventListener('loadend', (e) => {
            const dataJSON = e.srcElement.result;
            let datares = JSON.parse(dataJSON);
            if (datares.sta && datares.stacod === 200) {
              if (datares.msg == "gtoken") {
                // this.props.onAddParam({date:parseDate});
                sessionStorage.setItem("export_date", parseDate);
                // window.open(data.data)
                window.location.href = datares.data;
              }
              outer.setState({
                disableBtnSubmit: false
              });

            } else {
              outer.setState({
                disableBtnSubmit: false
              });
              outer.showDialog('Warning', datares.msg);
            }
          });

          fr.readAsText(data);
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"]);
      });
    });

  }

  isValidForm = () => {
    return (
      !this.state.error.date)
  }

  changeDate = (date) => {
    // let momentParse = moment(date).format("YYYY-MM-DD");
    console.log(date);
    this.setState({
      date: date
    })
  }

  render() {
    const { classes, theme } = this.props;
    const { error, penatuas, fulltimers, locallist } = this.state;
    const title = "Laporan Harian"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Export
            </Link>
            <Link color="inherit" href="/bo/export/daily">
              Laporan Harian
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    clearable
                    required
                    value={this.state.date}
                    // placeholder="Tanggal"
                    label="Tanggal"
                    inputVariant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={date => this.changeDate(date)}
                    format="DD-MM-YYYY"
                    style={{ paddingRight: 5 }}

                  />
                  {
                    error.date &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.date}
                    />
                  }
                </Grid>
                {/* <Grid item xs={12}>
                  <div style={{ width: 265 }}>
                    <Autocomplete
                      options={locallist}
                      onFocus={(e)=>{this.inputChangeLocal('')}}
                      getOptionLabel={option => option.name}
                      value={this.state.selectedLocal}
                      onChange={this.changeLocal}
                      onInputChange={(event, val) => this.inputChangeLocal(val)}
                      renderInput={
                        param => (
                          <TextField
                            {...param}
                            id="outlined-basic"
                            // variant="outlined" 
                            label="Lokal"
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )
                      }
                    />
                  </div>
                </Grid> */}
              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                Export to Excel
              </Button>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  *Note:
                  <ul>
                    <li>
                      Jika laporan dibuat untuk minggu yang sedang berjalan maka tanggal pada laporan adalah tanggal realisasi dari hari Senin sampai dengan tanggal laporan.
                    </li>
                    <li>
                      Misal:
                      <ul>
                        <li>
                          Jika laporan hari Minggu tanggal 12 Juli 2020 maka realisasi adalah dari Senin tanggal 6 Juli 2020 s/d Minggu tanggal 12 Juli 2020.
                        </li>
                        <li>
                          Jika laporan hari Rabu tanggal 15 Juli 2020 maka realisasi adalah dari Senin tanggal 13 Juli 2020 s/d Rabu tanggal 15 Juli 2020.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Jika laporan dibuat untuk minggu-minggu sebelumnya maka tanggal pada laporan adalah tanggal realisasi dari hari Senin sampai dengan hari Minggu pada minggu tersebut.
                    </li>
                    <li>
                      Misal:
                      <ul>
                        <li>
                          Jika laporan hari Minggu tanggal 5 Juli 2020 maka realisasi adalah dari Senin tanggal 29 Juni 2020 s/d Minggu tanggal 5 Juli 2020.
                        </li>
                        <li>
                          Jika laporan hari Rabu tanggal 8 Juli 2020 maka realisasi adalah dari Senin tanggal 6 Juli 2020 s/d Minggu tanggal 12 Juli 2020.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbExportDailyEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  // console.log("state",state)
  return {
    param: state.param
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps, dispatchToProps
)(withStyles(styles)(withTheme(DbExportDailyEdit)));