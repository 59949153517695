import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select, InputAdornment, IconButton,
  MenuItem, Breadcrumbs, Link, OutlinedInput, InputLabel
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../config/axios';
import DialogBasic from '../../../component/dialogbasic';
import { ValidateEmail } from '../../../utility/validation';
import Errormsg from "../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam, addToken } from "../../../store/actions/configAction";
import urlConfig from "../../../config/backend";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../util/IntlMessages";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewProfile: '',
      form: {
        id: "",
        email: "",
        jalaemail: "",
        firstname: "",
        lastname: "",
        gender: "",
        birthdate: new Date(),
        married: "",
        child: 0,
        address1: "",
        address2: "",
        mobile1: "",
        mobile2: "",
        city: "",
        country: "",
        province: "",
        pwd: "",
        profilepict: null,
        local: {},
        hall: "",
      },
      error: {
        id: "",
        email: "",
        jalaemail: "",
        firstname: "",
        lastname: "",
        gender: "",
        birthdate: "",
        married: "",
        child: "",
        address1: "",
        address2: "",
        mobile1: "",
        mobile2: "",
        city: "",
        country: "",
        province: "",
        pwd: "",
        hall: "",
        profilepict: null
      },
      dialogOpen: false,
      showPassword: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      mode: 'add',
      alowPeriod: []
    };
  }



  componentDidMount() {
    this.getProfile();
  }

  getProfile = () => {
    let outer = this;
    let urlGetProf = 'bo/int/user/get';
    if (this.props.token.role == "User") {
      urlGetProf = '/bo/ft/profile/get';
    }
    axios.post(urlGetProf, {}
    ).then(({ data }) => {
      if (data.sta && data.stacod == 200) {
        let user = data.data;
        outer.setState({
          form: {
            email: user.email,
            jalaemail: this.props.token.role == "User" ? user.jalaemail : "",
            firstname: user.firstname,
            lastname: user.lastname,
            gender: user.gender,
            birthdate: new Date(user.birthdate),
            married: this.props.token.role == "User" ? user.married : "",
            child: this.props.token.role == "User" ? user.child : 0,
            address1: user.address1,
            address2: user.address2,
            mobile1: user.mobile1,
            mobile2: user.mobile2,
            city: user.city,
            country: user.country,
            province: user.province,
            local: user.lokal.name,
            hall: user.hall
          },
          previewProfile: user.pict != "" ?
            urlConfig.urlBackendProfile + user.pict : ""
        })
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    })
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      this.setState({ dialogOpen: false });
    }
  }

  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  focusOut = (e) => {
    let { form } = this.state;
    let inputValue = e.target.value;
    if (e.target.value == "") {
      form['child'] = 0;
    } else {
      form['child'] = inputValue;
    }
    this.setState({
      form: form
    })
  }

  handleChange = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if (inputName === "accept") {
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if (newAccept === true) {
        error[inputName] = ""
      }
    }

    this.setState({
      form: form,
      error: error
    }, () => {
      switch (inputName) {
        case "mobile1":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        case "mobile2":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        case "child":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        default:
          break;
      }
    });
  }

  handleValidation = (e) => {
    let { form, error, mode } = this.state;

    if (form.email.trim().length === 0) {
      error.email = <IntlMessages id="master.user.error.email" />;
    } else {
      error.email = "";
    }

    if (form.firstname.trim().length === 0) {
      error.firstname = <IntlMessages id="master.user.error.firstname" />;
    } else {
      error.firstname = "";
    }

    if (form.gender.trim().length === 0) {
      error.gender = <IntlMessages id="master.user.error.gender" />;
    } else {
      error.gender = "";
    }

    if (this.props.token.role == "User") {

      if (form.lastname.trim().length === 0) {
        error.lastname = <IntlMessages id="master.user.error.lastname" />;
      } else {
        error.lastname = "";
      }

      if (form.married.trim().length === 0) {
        error.married = <IntlMessages id="master.user.error.married" />;
      } else {
        error.married = "";
      }

      if (form.child < 0) {
        error.child = <IntlMessages id="master.user.error.child" />;
      } else {
        error.child = "";
      }

      // if (form.jalaemail.trim().length === 0) {
      //   error.jalaemail = <IntlMessages id="master.user.error.jalaemail" />;
      // } else {
      //   error.jalaemail = "";
      // }

    }


    // if(form.pwd.trim().length === 0 && mode == 'add'){
    //   error.pwd=<IntlMessages id="master.user.error.pwd"/>;
    // }else{
    //   error.pwd="";
    // }

    if (!this.isValidForm()) {
      form.accept = false;
      error.accept = "";
    }

    this.setState({
      form: form,
      error: error
    }, () => {

      if (!this.isValidForm()) {
        return;
      }

      this.handleSubmit();

    });

  }

  handleSubmit = () => {
    let { form } = this.state;
    const formData = new FormData();
    Object.entries(form).map(([key, value]) => {
      if (value != undefined) {
        switch (key) {
          case 'birthdate':
            formData.append(key, formatDate(value));
            break;
          default:
            formData.append(key, value)
            break;
        }
      } else {
        formData.append(key, '')
      }
    });

    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      let urlUpdateUsr = 'bo/int/user/update';
      let content = formData;
      if (this.props.token.role == "User") {
        urlUpdateUsr = 'bo/ft/profile/set';
        form.child = parseInt(form.child);
        content = form;
      }
      axios.post(urlUpdateUsr, content
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          if (this.props.token.role != "User" && data.data) {
            let token = this.props.token;
            token.pict = data.data;
            this.props.onAddToken({ token: token })
          }
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.showDialog('Informasi', <IntlMessages id="success.editprofile" />);
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg);
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"]);
      });
    });

  }

  isValidForm = () => {
    return (
      !this.state.error.email &&
      !this.state.error.firstname &&
      !this.state.error.lastname &&
      !this.state.error.gender &&
      !this.state.error.pwd)
  }


  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log("e detail", e.target.files[0]);

    reader.onloadend = () => {
      this.setState({
        form: {
          ...this.state.form,
          profilepict: file
        },
        previewProfile: reader.result
      }, () => {
        if (this.props.token.role == "User" || this.props.token.role == "Penatua") {
          this.updatepp();
        }
      });
    }

    reader.readAsDataURL(file)
  }

  updatepp() {
    let { form } = this.state;
    const formData = new FormData();
    formData.append('pict', this.state.form.profilepict);

    const outer = this;
    axios.post('bo/ft/profile/updatepp', formData
    ).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        let token = this.props.token;
        token.pict = data.data;
        this.props.onAddToken({ token: token });
        outer.showDialog('Informasi', "Sukses mengubah gambar profil");
      } else {
        outer.showDialog('Warning', data.msg);
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"]);
    });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { classes, theme } = this.props;
    const { error, alowPeriod } = this.state;
    const title = "Profile"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Ubah Profil
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>


              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label={<IntlMessages id="emailaddress" />}
                    value={this.state.form.email}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    error={error.email ? true : false}
                  />
                  {
                    error.email &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.email}
                    />
                  }
                </Grid>

                {
                  this.props.token.role == "User" &&
                  <Grid item xs={12}>
                    <TextField
                      id="jalaemail"
                      name="jalaemail"
                      label={<IntlMessages id="master.user.jalaemail" />}
                      value={this.state.form.jalaemail}
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={this.handleChange}
                      onBlur={this.focusOut}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // error={error.jalaemail ? true : false}
                    />
                    {
                      // error.jalaemail &&
                      // <FormControlLabel
                      //   classes={{ label: classes.labelerror }}
                      //   control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      //   label={error.jalaemail}
                      // />
                    }
                  </Grid>
                }

                <Grid item xs={12}>
                  <FormControl component="fieldset" className={classes.formControl} style={{ width: '100%' }}>
                    <InputLabel style={{ top: -6, left: 13 }} shrink component="legend" htmlFor='pwd' error={error.pwd ? true : false}><IntlMessages id="password" /></InputLabel>
                    <OutlinedInput
                      id="pwd"
                      name="pwd"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.form.pwd}
                      onChange={this.handleChange}
                      label="Password"
                      required={this.state.mode == 'add'}
                      notched={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {
                      error.pwd &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.pwd}
                      />
                    }
                  </FormControl>
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    id="firstname"
                    name="firstname"
                    label={<IntlMessages id="master.user.firstname" />}
                    value={this.state.form.firstname}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.firstname ? true : false}
                  />
                  {
                    error.firstname &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.firstname}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="lastname"
                    name="lastname"
                    label={<IntlMessages id="master.user.lastname" />}
                    value={this.state.form.lastname}
                    size="small"
                    variant="outlined"
                    required={this.props.token.role == "User"}
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.lastname ? true : false}
                  />
                  {
                    error.lastname &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.lastname}
                    />
                  }
                </Grid>
                {
                  this.props.token.role == "User" &&
                  <Grid item xs={12}>
                    <TextField
                      id="local"
                      name="local"
                      label="Lokal"
                      value={this.state.form.local}
                      size="small"
                      variant="outlined"
                      fullWidth
                      disabled
                      // onChange={this.handleChange}
                      // onBlur={this.focusOut}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    // error={error.jalaemail ? true : false}
                    />
                  </Grid>
                }
                {
                  this.props.token.role == "User" &&
                  <Grid item xs={12}>
                    <TextField
                      id="hall"
                      name="hall"
                      label="Hall"
                      value={this.state.form.hall}
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={this.handleChange}
                      onBlur={this.focusOut}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    // error={error.hall ? true : false}
                    />
                    {
                      // error.hall &&
                      // <FormControlLabel
                      //   classes={{ label: classes.labelerror }}
                      //   control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      //   label={error.hall}
                      // />
                    }
                  </Grid>
                }

                <Grid item xs={12}>
                  <FormControl component="fieldset" className={classes.formControl} style={{ width: '100%' }}>
                    <FormLabel component="legend" error={error.gender ? true : false} style={{ fontSize: 12 }}><IntlMessages id="master.user.gender" />*</FormLabel>
                    <RadioGroup aria-label="gender" id="gender" name="gender" value={this.state.form.gender} onChange={this.handleChange}>
                      <FormControlLabel value="Male" control={<Radio />} label={<IntlMessages id="master.user.male" />} />
                      <FormControlLabel value="Female" control={<Radio />} label={<IntlMessages id="master.user.female" />} />
                    </RadioGroup>
                    {
                      error.gender &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.gender}
                      />
                    }
                  </FormControl>
                </Grid>

                {/* TODO: Buat pakai strip / component material picker? */}
                <Grid item xs={12}>
                  {/* <TextField
                        id="birthdate"
                        label="Tanggal Lahir"
                        type="date"
                        defaultValue="2017-05-24"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> */}
                  <FormControl component="fieldset" className={classes.formControl} style={{ width: '100%' }}>
                    {/* <FormLabel component="legend" error={error.birthdate?true:false} style={{fontSize:12}}><IntlMessages id="master.user.birthdate"/></FormLabel> */}
                    <KeyboardDatePicker
                      clearable
                      label={<IntlMessages id="master.user.birthdate" />}
                      required
                      inputVariant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.form.birthdate}
                      placeholder="10-10-2018"
                      onChange={date => this.setState({ form: { ...this.state.form, birthdate: date } })}
                      maxDate={new Date()}
                      format="DD-MM-YYYY"
                    />
                    {
                      error.birthdate &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.birthdate}
                      />
                    }
                  </FormControl>
                </Grid>
                {
                  this.props.token.role == "User" &&
                  <Grid item xs={12}>
                    <FormControl component="fieldset" className={classes.formControl} style={{ width: '100%' }}>
                      <FormLabel component="legend" error={error.married ? true : false} style={{ fontSize: 12 }}><IntlMessages id="master.user.married" />*</FormLabel>
                      <RadioGroup aria-label="married" id="married" name="married" value={this.state.form.married} onChange={this.handleChange}>
                        <FormControlLabel value="Married" control={<Radio />} label={<IntlMessages id="master.user.marriedstat" />} />
                        <FormControlLabel value="Single" control={<Radio />} label={<IntlMessages id="master.user.singlestat" />} />
                      </RadioGroup>
                      {
                        error.married &&
                        <FormControlLabel
                          classes={{ label: classes.labelerror }}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.married}
                        />
                      }
                    </FormControl>
                  </Grid>
                }
                {
                  this.props.token.role == "User" &&
                  <Grid item xs={12}>
                    <TextField
                      id="child"
                      name="child"
                      disabled={this.state.form.married == "Single"}
                      label={<IntlMessages id="master.user.child" />}
                      value={this.state.form.child}
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={this.handleChange}
                      onBlur={this.focusOut}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={error.child ? true : false}
                    />
                    {
                      error.child &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.child}
                      />
                    }
                  </Grid>
                }
                <Grid item xs={12}>
                  <TextField
                    id="address1"
                    name="address1"
                    label={<IntlMessages id="master.user.address1" />}
                    value={this.state.form.address1}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.address1 ? true : false}
                  />
                  {
                    error.address1 &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.address1}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="address2"
                    name="address2"
                    label={<IntlMessages id="master.user.address2" />}
                    value={this.state.form.address2}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.address2 ? true : false}
                  />
                  {
                    error.address2 &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.address2}
                    />
                  }
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    id="country"
                    name="country"
                    label={<IntlMessages id="master.user.country" />}
                    value={this.state.form.country}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.country ? true : false}
                  />
                  {
                    error.country &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.country}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="province"
                    name="province"
                    label={<IntlMessages id="master.user.province" />}
                    value={this.state.form.province}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.province ? true : false}
                  />
                  {
                    error.province &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.province}
                    />
                  }
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    id="city"
                    name="city"
                    label={<IntlMessages id="master.user.city" />}
                    value={this.state.form.city}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.city ? true : false}
                  />
                  {
                    error.city &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.city}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="mobile1"
                    name="mobile1"
                    label={<IntlMessages id="master.user.mobile1" />}
                    value={this.state.form.mobile1}
                    size="small"
                    variant="outlined"
                    required={this.props.token.role == "User"}
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.mobile1 ? true : false}
                  />
                  {
                    error.mobile1 &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.mobile1}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="mobile2"
                    name="mobile2"
                    label={<IntlMessages id="master.user.mobile2" />}
                    value={this.state.form.mobile2}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.mobile2 ? true : false}
                  />
                  {
                    error.mobile2 &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.mobile2}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <label>Profile Picture: </label>
                  <br />
                  <img
                    src={this.state.previewProfile}
                    style={{ width: 100, marginBottom: 10 }}
                  />
                  <br />
                  <input
                    type="file"
                    onChange={(e) => this.handleImageChange(e)}
                  />
                </Grid>

              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                UPDATE
              </Button>
            </form>
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbProfileEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  console.log("state", state)
  return {
    param: state.param,
    token: state.token.token
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddToken: token => dispatch(addToken(token))
  };
};

export default connect(
  mapStatestoProps, dispatchToProps
)(withStyles(styles)(withTheme(DbProfileEdit)));