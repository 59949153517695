import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";

const queryString = require('query-string');

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbMasterTypeEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          id: "",
          name:"",
          desc:"",
          target: 0,
          married: "",
          child: 0
          // desc:""
          
        },
        error:{
          id: "",
          name:"",
          desc:"",
          target: "",
          married: "",
          child: ""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        filtertable:{},
    };
  }

  componentDidMount() {
    // console.log("user ", this.props.param);
    let qparam = queryString.parse(this.props.location.search); 
    let filter= this.props.location.state.filter;
    this.setState({filtertable:filter});
    // console.log("qparam ",qparam);
    if(qparam && qparam.i){
      if(this.props.param != null){
        let typ = this.props.param.detail;
        this.setState({
          form:{
            id:typ.id,
            name:typ.name,
            desc:typ.desc,
            target:typ.target,
            married:typ.married,
            child:typ.child,
          },
          mode:'edit'
        })
      }else{
        //get detail
        this.getTypeDetail(qparam.i);
      }
    }
    // if(isEmpty(this.props.param) != true){
    //   let typ = this.props.param.detail;
    //   this.setState({
    //     form:{
    //       id:typ.id,
    //       name:typ.name,
    //       desc:typ.desc,
    //       target:typ.target,
    //       married:typ.married,
    //       child:typ.child,
    //     },
    //     mode:'edit'
    //   });
    // }
  }

  getTypeDetail = (id) => {
    let outer = this;
    axios.post('bo/int/master/fttype', {id:id}).then(({data})=>{
      if(data.sta && data.stacod === 200){        
        let typ = data.data;
        this.setState({
          form:{
            id:typ.id,
            name:typ.name,
            desc:typ.desc,
            target:typ.target,
            married:typ.married,
            child:typ.child,
          },
          mode:'edit'
        })
        // outer.setState({userList:data.data});
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      if(this.state.dialogTitle != "Information") {
        this.setState({dialogOpen:false});
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          // this.props.history.push('/bo/master/type/list');
          this.backToList();
          this.props.deselectParam();
        })
      }
    }
  }
  backToList=()=>{
    this.props.history.push({ pathname: "/bo/master/type/list", filter:this.state.filtertable });
  }
  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  changeFocus = (e) => {
    let {form} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if(inputValue == "") {
      form[inputName] = 0;
    } else {
      form[inputName] = inputValue;
    }

    this.setState({
      form: form
    })
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    }, () => {
      switch(inputName){
        case "target":
          if(inputValue){
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break; 
        case "child":
          if(inputValue){
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;  
         
        default:
        break;
      }
    });
  }

  handleValidation = (e) => {
    let {form,error,mode} = this.state;

    if(form.name.trim().length === 0){
      error.name=<IntlMessages id="master.type.error.name"/>;
    }else{
      error.name="";
    }

    if(form.desc.trim().length === 0){
      error.desc=<IntlMessages id="master.type.error.desc"/>;
    }else{
      error.desc="";
    }

    if(form.married.trim().length === 0){
      error.married=<IntlMessages id="master.user.error.married"/>;
    }else{
      error.married="";
    }



    

    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:form,
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;
    form.child = form.child == 0 || form.child == "" ? 0 : parseInt(form.child);
    form.target = form.target == 0 || form.target == "" ? 0 : parseFloat(form.target);
    const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        if(this.state.mode == "add") {

          axios.post('bo/int/master/fttype/add', form
          ).then(({data}) => {
            if (data.sta && data.stacod === 200) {
              outer.setState({
                form: {
                  name:"",
                  desc:"",
                  target:0,
                  married:"",
                  child: 0
                },
                error:{
                  name:"",
                  desc:"",
                  target:"",
                  married:"",
                  child: ""
                },
                disableBtnSubmit:false
              }, () => {
                outer.showDialog('Information',<IntlMessages id="master.type.success.addtype"/>);
              });
              
            } else {
              outer.setState({
                disableBtnSubmit:false
              });
              outer.showDialog('Warning',data.msg);
            }
          }).catch(function (error) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',Errormsg["500"]);
          });
        } else {
          axios.post('bo/int/master/fttype/edit', form
          ).then(({data}) => {
            if (data.sta && data.stacod === 200) {
              outer.setState({
                disableBtnSubmit:false
              }, () => {
                outer.showDialog('Information',<IntlMessages id="master.type.success.edittype"/>);
              });
              
            } else {
              outer.setState({
                disableBtnSubmit:false
              });
              outer.showDialog('Warning',data.msg);
            }
          }).catch(function (error) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',Errormsg["500"]);
          });
        }
      });

  }

  isValidForm = () => {
    return (
    !this.state.error.name &&     
    !this.state.error.desc &&
    !this.state.error.married )
  }

  

 

  render(){
    const { classes, theme } = this.props;
    const { error, penatuas, fulltimers } = this.state;
    const title = "Profile"
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="inherit" 
            // href="/bo/master/type/list"
            href='#'
            onClick={()=>this.backToList()}
            >
              Daftar Status
            </Link>
            <Link color="textPrimary" href="/bo/master/type/edit">
              {
                this.state.mode == "add" ?
                "Tambah Status" : "Ubah Status"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>

                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        name="name"
                        label={<IntlMessages id="master.type.name"/>}
                        value={this.state.form.name} 
                        size="small"
                        variant="outlined"
                        required
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.name?true:false}
                      />
                        {
                            error.name &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.name}
                              />
                        }
                    </Grid>               
                    <Grid item xs={12}>
                      <TextField
                        id="desc"
                        name="desc"
                        label={<IntlMessages id="master.type.desc"/>}
                        value={this.state.form.desc} 
                        size="small"
                        variant="outlined"
                        required
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.desc?true:false}
                      />
                        {
                            error.desc &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.desc}
                              />
                        }
                    </Grid>               
                    <Grid item xs={12}>
                      <TextField
                        id="target"
                        name="target"
                        label={<IntlMessages id="master.type.target"/>}
                        value={this.state.form.target}
                        size="small"
                        variant="outlined"
                        fullWidth
                        onBlur={this.changeFocus}
                        onChange={this.handleChange} 
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.target?true:false}
                      />
                        {
                            error.target &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.target}
                              />
                        }
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" className={classes.formControl} style={{width:'100%'}}>
                        <FormLabel component="legend" error={error.married?true:false} style={{fontSize:12}}><IntlMessages id="master.user.married"/>*</FormLabel>
                        <RadioGroup aria-label="married" id="married" name="married" value={this.state.form.married} onChange={this.handleChange} required>
                          <FormControlLabel value="Married" control={<Radio />} label={<IntlMessages id="master.user.marriedstat"/>} />
                          <FormControlLabel value="Single" control={<Radio />} label={<IntlMessages id="master.user.singlestat"/>} />
                        </RadioGroup>
                        {
                          error.married &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.married}
                            />
                        }
                      </FormControl>
                    </Grid>                  
                    <Grid item xs={12}>
                      <TextField
                        id="child"
                        name="child"
                        disabled={this.state.form.married == "Single"}
                        label={<IntlMessages id="master.user.child"/>}
                        value={this.state.form.child}
                        size="small"
                        variant="outlined"
                        fullWidth
                        onBlur={this.changeFocus}
                        onChange={this.handleChange} 
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.child?true:false}
                      />
                        {
                            error.child &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.child}
                              />
                        }
                    </Grid>                  
      
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    UPDATE
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbMasterTypeEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  console.log("state",state)
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbMasterTypeEdit)));