import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
    IconButton, TextField, Paper, Card, FormControl,
    CardMedia, CardContent, Typography, Select, InputLabel, MenuItem, Button, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import DataTable, { defaultThemes } from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";
import './../../../../App.css';
import list from '../../../master/user/list';

const customStyles = {
    tableWrapper: {
        style: {
            display: "inline"
        }
    },
    headRow: {
        style: {
            borderBottomStyle: 'solid',
            borderBottomWidth: '0px',
            borderBottomColor: defaultThemes.default.divider.default,
        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px',
            borderRightStyle: 'solid',
            borderRightWidth: '1px',
            borderRightColor: defaultThemes.default.divider.default,
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: defaultThemes.default.divider.default,
        },
    },
    cells: {
        style: {
            borderRightStyle: 'solid',
            borderRightWidth: '1px',
            borderRightColor: defaultThemes.default.divider.default,
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: defaultThemes.default.divider.default,
        },
    },
    rows: {
        style: {
            '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '0px',
                borderBottomColor: defaultThemes.default.divider.default,
            },
        },
    },
    pagination: {
        style: {
            borderTopStyle: 'solid',
            borderTopWidth: '0px',
            borderTopColor: defaultThemes.default.divider.default,
        },
    }
};



const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    table: {
        minWidth: 320,
    },
    complete: {
        color: '#204196',
        fontWeight: 'bold'
    },
    incomplete: {
        color: '#ff1744',
        fontWeight: 'bold'
    }
});

const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "scrollFullHeight",
    rowsPerPageOptions: [10, 25, 100],
    print: false,
    download: false,
    selectableRows: 'single',
    isRowSelectable: function () { return false }
};

class laporanrekapjadwal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setdate: "",
            username: "",
            useremail: "",
            showdata: "table",
            loadingTrigger: false,
            page: {
                keyword: "",
                page: 1,
                count: 100,
                all: false
            },
            pageUser: {
                keyword: "",
                page: 1,
                results: 10
            },
            pagevw: {

            },
            localparam: {
                keyword: '',
                page: 1,
                count: 10
            },
            locallist: [],
            selectedLocal: {},
            loading: false,
            data: [],
            users: [],
            realList: [],
            realListTotal: 0,
            usrobject: null,
            dialogOpen: false,
            dialogTitle: '',
            dialogContent: '',
            dialogMode: '',
            detailOpen: false,
            disableBtnExport: false
        }
    }

    columns = [
        {
            name: "Name",
            selector: 'name',
            sortable: true,
            width: '180px'
        },
        {
            name: "Korporat Target",
            selector: 'korporat_target',
            right: true
        },
        {
            name: "Korporat Total",
            selector: 'korporat_total',
            right: true,
            format: row => Math.round((row.korporat_total + Number.EPSILON) * 100) / 100
        },
        {
            name: "Lewi Hall Target",
            selector: 'lewihall_target',
            right: true
        },
        {
            name: "Lewi Hall Total",
            selector: 'lewihall_total',
            right: true,
            format: row => Math.round((row.lewihall_total + Number.EPSILON) * 100) / 100
        },

        {
            name: "Lewi KS Target",
            selector: 'lewiks_target',
            right: true
        },
        {
            name: "Lewi KS Total",
            selector: 'lewiks_total',
            right: true,
            format: row => Math.round((row.lewiks_total + Number.EPSILON) * 100) / 100
        },
        {
            name: "Transport Target",
            selector: 'transport_target',
            right: true
        },
        {
            name: "Transport Total",
            selector: 'transport_total',
            right: true,
            format: row => Math.round((row.transport_total + Number.EPSILON) * 100) / 100
        },

        {
            name: "Lain-Lain Target",
            selector: 'lainnya_target',
            right: true
        },
        {
            name: "Lain-Lain Total",
            selector: 'lainnya_total',
            right: true,
            format: row => Math.round((row.lainnya_total + Number.EPSILON) * 100) / 100
        },
        {
            name: "Penuntutan Target",
            selector: 'penuntutan_target',
            right: true
        },
        {
            name: "Penuntutan Total",
            selector: 'penuntutan_total',
            right: true,
            format: row => Math.round((row.penuntutan_total + Number.EPSILON) * 100) / 100
        },

    ];

    componentDidMount() {
        this.getRealReport();
        this.getLocal();
    }

    getLocal() {
        let outer = this;
        axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
            if (data.sta && data.stacod === 200) {
                outer.setState({
                    locallist: data.data.list
                });
            } else {
                outer.showDialog('Warning', data.msg, "");
            }
        }).catch(function (error) {
            outer.showDialog('Warning', Errormsg["500"], "");
        });
    }

    changeLocal = (event, val) => {
        let outer = this;
        if (val) {
            this.setState({
                selectedLocal: val,
                page: {
                    ...this.state.page,
                    lokalid: val.id
                }
            }, () => { outer.getRealReport() });
        } else {
            this.setState({
                selectedLocal: {},
                page: {
                    ...this.state.page,
                    lokalid: "",
                }
            }, () => { outer.getRealReport() });
        }
        this.inputChangeLocal('');
    }

    inputChangeLocal(val) {
        this.setState({
            localparam: {
                ...this.state.localparam,
                keyword: val
            }
        }, () => {
            this.getLocal();
        })
    }

    showDialog = (title, content, mode) => {
        this.setState({
            dialogOpen: true,
            dialogTitle: title,
            dialogContent: content,
            dialogMode: mode
        });
    }

    handleDetailClose = () => {
        this.setState({
            detailOpen: false
        })
    }

    handleDialogClose = () => {
        this.setState({
            dialogOpen: false
        })
    }

    handleDialogOk = () => {
        if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
            this.setState({ dialogOpen: false });
            this.props.history.push("/logout");
        } else {
            this.setState({ dialogOpen: false });
        }
    }

    onChangeRows(val) {
        let outer = this;
        this.setState({
            page: {
                ...this.state.page,
                page: 1,
                count: val
            }
        }, () => {
            outer.getRealReport();
        })
    }

    onChangePage(val) {
        let outer = this;
        this.setState({
            page: {
                ...this.state.page,
                page: val
            }
        }, () => {
            outer.getRealReport();
        })

    }

    search(val) {
        let outer = this;
        this.setState({
            page: {
                ...this.state.page,
                keyword: val.target.value,
                page: 1
            }
        }, () => {
            outer.getRealReport();
        })
    }

    getRealReport = () => {
        let outer = this;
        this.setState({
            loadingTrigger: true
        });
        this.setState({
            loading: true
        }, () => {
            axios.post('/bo/int/report/schedule/nonshepherding/alluser', outer.state.page).then(({ data }) => {

                this.setState({
                    loadingTrigger: false
                });
                if (data.sta && data.stacod === 200) {
                    if (data.data.list.length > 0) {
                        outer.setState({
                            realList: data.data.list,
                            realListTotal: data.data.total
                        })
                    }
                } else {
                    outer.showDialog('Warning', data.msg, "");
                }
                this.setState({ loading: false });
            }).catch(function (error) {
                console.log(error)
                outer.showDialog('Warning', Errormsg["500"], "");
                this.setState({ loading: false });
            });
        })
    }

    convertArrayOfObjectsToCSV = (array) => {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = 'Nama,Korporat Target,Korporat Total,Lewi Hall Target,Lewi Hall Total,Lewi Kaum Saleh Target,Lewi Kaum Saleh Total,Transport Target,Transport Total,Lain-lain Target,Lain-lain Total,Penuntutan Target,Penuntutan Total';
        // result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                if (key != 'name') {
                    result += Math.round((item[key] + Number.EPSILON) * 100) / 100;
                } else {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    downloadCSV = (array) => {
        let outer = this;
        this.setState({
            disableBtnExport: true,
            loading: true
        }, () => {
            let page = {
                keyword: '',
                page: 1,
                count: 10,
                all: true
            }
            axios.post('/bo/int/report/schedule/nonshepherding/alluser', page).then(({ data }) => {
                if (data.sta && data.stacod === 200) {
                    if (data.data.list.length > 0) {
                        let array = data.data.list;
                        if (array.length > 0) {
                            const link = document.createElement('a');
                            let csv = this.convertArrayOfObjectsToCSV(array);
                            if (csv == null) return;

                            const filename = 'Rekap Jadwal Non Penggembalaan ' + moment().format('DD-MM-YYYY') + '.csv';

                            if (!csv.match(/^data:text\/csv/i)) {
                                csv = `data:text/csv;charset=utf-8,${csv}`;
                            }

                            link.setAttribute('href', encodeURI(csv));
                            link.setAttribute('download', filename);
                            link.click();
                        }
                        outer.setState({ disableBtnExport: false, loading: false });
                    }
                } else {
                    outer.setState({ disableBtnExport: false, loading: false });
                    outer.showDialog('Warning', data.msg, "");
                }
                this.setState({ loading: false });
            }).catch(function (error) {
                outer.showDialog('Warning', Errormsg["500"], "");
                outer.setState({ disableBtnExport: false, loading: false });
            });
        })
    }


    render() {
        const { theme } = this.props;
        const { realList, users, locallist } = this.state;

        return (
            <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
                <div style={{ paddingTop: 10 }}>
                    <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Laporan Rekap Jadwal
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/report/laporanrekapjadwal/alluser'>
               Laporan Rekap Jadwal
            </Link>            
            </Breadcrumbs>
                </div>
                <div className="filter">
                    <div style={{ marginBottom: 10 }}>
                        <Autocomplete
                            options={locallist}
                            getOptionLabel={option => option.name}
                            value={this.state.selectedLocal}
                            onChange={this.changeLocal}
                            onFocus={(e)=>{this.inputChangeLocal('')}}
                            onInputChange={(event, val) => this.inputChangeLocal(val)}
                            renderInput={
                                param => (
                                    <TextField
                                        {...param}
                                        id="outlined-basic"
                                        // variant="outlined" 
                                        label="Lokal"
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )
                            }
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            id="outlined-basic"
                            label="Search FT"
                            variant="outlined"
                            size="small"
                            style={{ margin: '5px' }}
                            onChange={(val) => this.search(val)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => this.downloadCSV()} color="primary"
                            disabled={this.state.disableBtnExport}
                            variant="contained" fullWidth>Export to CSV</Button>
                    </div>
                </div>

                <DataTable
                    columns={this.columns}
                    data={realList}
                    noDataComponent={
                        <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                            <CardMedia style={{ textAlign: 'center' }}>
                                <MoodBad color="action" style={{
                                    fontSize: 40,
                                    marginTop: 10,
                                    marginBottom: 10
                                }} />
                            </CardMedia>
                            <CardContent>
                                <Typography>
                                    Data tidak tersedia
                                </Typography>
                            </CardContent>
                        </Card>
                    }
                    highlightOnHover
                    noHeader
                    customStyles={customStyles}
                    pagination={true}
                    paginationServer
                    paginationRowsPerPageOptions={[100,250,500]}
                    paginationTotalRows={this.state.realListTotal}
                    paginationPerPage={this.state.page.count}
                    onChangePage={(val) => this.onChangePage(val)}
                    onChangeRowsPerPage={(val) => this.onChangeRows(val)}
                    progressPending={this.state.loadingTrigger}
                    progressComponent={
                        <Loader type="TailSpin" color="#204196" height={100} width={100} />
                    }
                />

                <DialogBasic
                    open={this.state.dialogOpen}
                    title={this.state.dialogTitle}
                    content={this.state.dialogContent}
                    handleClose={this.handleDialogClose}
                    handleOk={this.handleDialogOk}
                />

            </div>
        );
    }
}

laporanrekapjadwal.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
    return {
        token: state.token
    };
};

const dispatchToProps = dispatch => {
    return {
        onAddParam: param => dispatch(addParam(param))
    };
};

export default connect(
    mapStatestoProps,
    dispatchToProps
)(withStyles(styles)(withTheme(laporanrekapjadwal)));




















