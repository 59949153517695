import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, InputLabel, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {Autocomplete} from '@material-ui/lab';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";

const queryString = require('query-string');

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbMasterSubServiceTypeEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          id: "",
          servicetypeid: "",
          name:"",
          desc:"",
          tasktype: ""
          
        },
        error:{
          id: "",
          servicetypeid: "",
          name:"",
          desc:""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        stpage: {
          keyword: '',
          count: 10
        },
        stobject: null,
        servicetypes: [],
        mode:'add',
        alowPeriod:[],
        filtertable:{},
        filterAll:{}
    };
  }

  componentDidMount() {
    // this.loadData();
    let outer = this;
    this.getServiceType();
    let qparam = queryString.parse(this.props.location.search); 
    let filter= this.props.location.state.filter;
    this.setState({filtertable:filter, filterAll:this.props.location.state.filterAll});
    // console.log("qparam ",qparam);
    if(qparam && qparam.i){
      if(this.props.param != null){
        let serv = this.props.param.detail;
        this.setState({
          form:{
            id:serv.id,
            servicetypeid: serv.servicetypeid,
            name:serv.name,
            desc:serv.desc,
            tasktype: serv.tasktype
          },
          mode:'edit'
        }, () => {
          outer.getEditServiceType();
        })
      }else{
        //get detail
        this.getSubServDetail(qparam.i);
      }
    }
  }

  getSubServDetail = (id) => {
    let outer = this;
    axios.post('bo/int/master/subservicetype', {id:id}).then(({data})=>{
      if(data.sta && data.stacod === 200){        
        let serv = data.data;
        this.setState({
          form:{
            id:serv.id,
            servicetypeid: serv.servicetypeid,
            name:serv.name,
            desc:serv.desc,
            tasktype: serv.tasktype
          },
          mode:'edit'
        }, () => {
          outer.getEditServiceType();
        })
        // outer.setState({userList:data.data});
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  getEditServiceType() {
    let outer = this;
    axios.post('bo/int/master/servicetype', {id: this.state.form.servicetypeid}).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          stobject:data.data
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  inputChangeServiceType(val) {
    this.setState({
      stpage: {
        ...this.state.stpage,
        keyword: val
      }
    }, () => {
      this.getServiceType();
    })
  }

  getServiceType() {
    let outer = this;
    axios.post('bo/int/master/servicetype/list', outer.state.stpage).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          servicetypes:data.data.list
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  changeServiceType = (event, val) => {
    console.log("val service type", val)
    if(val) {

      this.setState({
        stobject: val,
        form: {
          ...this.state.form,
          servicetypeid: val.id
        }
      });
    } else {
      this.setState({
        stobject: null,
        form: {
          ...this.state.form,
          servicetypeid: "",
        }
      })
    }
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      if(this.state.dialogTitle != "Information") {
        this.setState({dialogOpen:false});
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          // this.props.history.push("/bo/master/subservicetype/list");
          this.backToList();
          this.props.deselectParam();
        })
      }
    }
  }
  
  backToList=()=>{
    this.props.history.push({ pathname: "/bo/master/subservicetype/list", filter:this.state.filtertable, filterAll:this.state.filterAll });
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    });
  }

  handleChangeTask = (e) => {
    this.setState({
      form : {
        ...this.state.form,
        tasktype: e.target.value
      }
    })
  }

  handleValidation = (e) => {
    let {form,error,mode} = this.state;

    if(form.servicetypeid.trim().length === 0){
      error.servicetypeid=<IntlMessages id="master.subservicetype.error.servicetype"/>;
    }else{
      error.servicetypeid="";
    }

    if(form.name.trim().length === 0){
      error.name=<IntlMessages id="master.subservicetype.error.name"/>;
    }else{
      error.name="";
    }

    if(form.desc.trim().length === 0){
      error.desc=<IntlMessages id="master.subservicetype.error.desc"/>;
    }else{
      error.desc="";
    }
    
    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:form,
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;
    if(this.state.mode == 'add'){

      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/subservicetype/add', form
        ).then(({data}) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              form: {
                id:"",
                servicetypeid: "",
                name:"",
                desc:""
              },
              stobject: null,
              error:{
                id:"",
                servicetypeid: "",
                name:"",
                desc:""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.subservicetype.success.addservice"/>);
            });
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }else{
      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/subservicetype/edit', form
        ).then(({data}) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              form: {
                id:"",
                servicetypeid: "",
                name:"",
                desc:""
              },
              error:{
                id:"",
                servicetypeid: "",
                name:"",
                desc:""
              },
              stobject: null,
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.subservicetype.success.editservice"/>);
            });
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });
    }
  }

  isValidForm = () => {
    return (
    !this.state.error.servicetypeid &&     
    !this.state.error.name &&     
    !this.state.error.desc )
  }

  render(){
    const { classes, theme } = this.props;
    const { error, servicetypes } = this.state;
    const title = "Profile"
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="inherit" 
            // href="/bo/master/subservicetype/list"
            href='#'
            onClick={()=>{this.backToList();}}
            >
              Daftar Sub Tipe Pelayanan
            </Link>
            <Link color="textPrimary" href="/bo/master/subservicetype/edit">
              {
                this.state.mode == "add" ?
                "Tambah Sub Tipe Pelayanan" : "Ubah Sub Tipe Pelayanan"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>

                  
                  <Grid container spacing={2}>

                    <Grid item xs={12}>
                    <Autocomplete
                        options={servicetypes}
                        getOptionLabel={option => option.name}
                        value={this.state.stobject}
                        onChange={this.changeServiceType}
                        onFocus={(e)=>{this.inputChangeServiceType('')}}
                        onInputChange={(event, val) => this.inputChangeServiceType(val)}
                        renderInput={
                          param => (
                            <TextField 
                              {...param}
                              id="servicetype"
                              label="Tipe Pelayanan" 
                              variant="outlined"
                              size="small"
                              fullWidth
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={error.servicetypeid?true:false}
                            />
                          )
                        }
                      />
                      {
                          error.servicetypeid &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.servicetypeid}
                            />
                      }
                    </Grid>
      
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        name="name"
                        label={<IntlMessages id="master.subservicetype.name"/>}
                        value={this.state.form.name} 
                        size="small"
                        variant="outlined"
                        required
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.name?true:false}
                      />
                        {
                            error.name &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.name}
                              />
                        }
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="desc"
                        name="desc"
                        label={<IntlMessages id="master.subservicetype.desc"/>}
                        value={this.state.form.desc}
                        rowsMax={4} 
                        size="small"
                        variant="outlined"
                        required
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.desc?true:false}
                      />
                        {
                            error.desc &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.desc}
                              />
                        }
                    </Grid>
                    {/* {
                      this.state.stobject &&
                      this.state.stobject.name == "Korporat" &&
                      <Grid item xs={12}>
                        <InputLabel id="formtask">
                          Tipe Tugas
                        </InputLabel>
                        <Select
                          value={this.state.form.tasktype}
                          onChange={this.handleChangeTask}
                          labelId="formtask"
                          fullWidth
                        >
                          <MenuItem value="Kelompok">Kelompok</MenuItem>
                          <MenuItem value="Tugas">Tugas</MenuItem>
                        </Select>
                      </Grid>
                    } */}
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    UPDATE
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbMasterSubServiceTypeEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  console.log("state",state)
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbMasterSubServiceTypeEdit)));