import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link, InputLabel
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";

const queryString = require('query-string');

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbMasterCategoryEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          id: "",
          name:"",
          desc:"",
          periode: ""
          
        },
        error:{
          id: "",
          name:"",
          periode: ""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        alowPeriod:[],
        filtertable:{},
    };
  }

  componentDidMount() {
    // this.loadData();
    let outer = this;
    let qparam = queryString.parse(this.props.location.search); 
    let filter= this.props.location.state.filter;
    this.setState({filtertable:filter});
    // console.log("qparam ",qparam);
    if(qparam && qparam.i){
      if(this.props.param != null){
        let cat = this.props.param.detail;
        this.setState({
          form:{
            id:cat.id,
            name:cat.name,
            desc:cat.desc,
            periode: cat.periode
          },
          mode:'edit'
        })
      }else{
        //get detail
        this.getCatDetail(qparam.i);
      }
    }
  }

  getCatDetail = (id) => {
    let outer = this;
    axios.post('bo/int/master/category', {id:id}).then(({data})=>{
      if(data.sta && data.stacod === 200){        
        let cat = data.data;
        this.setState({
          form:{
            id:cat.id,
            name:cat.name,
            desc:cat.desc,
            periode: cat.periode
          },
          mode:'edit'
        })
        // outer.setState({userList:data.data});
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      if(this.state.dialogTitle != "Information") {
        this.setState({dialogOpen: false});
      } else {
        this.setState({
          dialogOpen:false
        }, () => {
          // this.props.history.push("/bo/master/category/list");
          this.backToList();
          this.props.deselectParam();
        });

      }
    }
  }

  backToList=()=>{
    this.props.history.push({ pathname: "/bo/master/category/list", filter:this.state.filtertable });
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    });
  }

  handleValidation = (e) => {
    let {form,error,mode} = this.state;

    if(form.name.trim().length === 0){
      error.name=<IntlMessages id="master.category.error.name"/>;
    }else{
      error.name="";
    }

    if(form.periode.trim().length === 0){
      error.periode=<IntlMessages id="master.category.error.periode"/>;
    }else{
      error.periode="";
    }    

    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:form,
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;


    if(this.state.mode == 'add'){

      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/category/add', form
        ).then(({data}) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              form: {
                id:"",
                name:"",
                desc:"",
                periode: ""
              },
              error:{
                id:"",
                name:"",
                periode: ""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.category.success.addcategory"/>);
            });
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }else{
      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/category/edit', form
        ).then(({data}) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              form: {
                id:"",
                name:"",
                desc:"",
                periode: ""
              },
              error:{
                id:"",
                name:"",
                periode: ""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.category.success.editcategory"/>);
            });
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }

  }

  isValidForm = () => {
    return (
    !this.state.error.name &&
    !this.state.error.periode)
  }

  handleChangeInput = (val) => {
    this.setState({
      form: {
        ...this.state.form,
        periode: val.target.value
      }
    })
  }

  

 

  render(){
    const { classes, theme } = this.props;
    const { error, alowPeriod } = this.state;
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="inherit" 
            // href="/bo/master/category/list"
            href='#'
            onClick={()=>{this.backToList()}}
            >
              Daftar Kategori
            </Link>
            <Link color="textPrimary" href="/bo/master/category/edit">
              {
                this.state.mode == "add" ?
                "Tambah Kategori" : "Ubah Kategori"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <image src={this.state.previewProfile} style={{width: 100}}/>
              <form className={classes.form} noValidate>

                  
                  <Grid container spacing={2}>
      
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        name="name"
                        label={<IntlMessages id="master.category.name"/>}
                        value={this.state.form.name} 
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        // disabled={this.state.mode == 'edit'}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.name?true:false}
                      />
                        {
                            error.name &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.name}
                              />
                        }
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="desc"
                        name="desc"
                        label={<IntlMessages id="master.category.desc"/>}
                        value={this.state.form.desc} 
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.desc?true:false}
                      />
                        {
                            error.desc &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.desc}
                              />
                        }
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        style={{
                          minWidth: '100%',
                          marginBottom: 10
                        }}
                      >
                      <InputLabel id="formperiode" style={{backgroundColor:"#fff", paddingLeft: 4, paddingRight: 4}} shrink variant="outlined">
                          <IntlMessages id="master.category.periode"/><span> *</span>
                      </InputLabel>
                      <Select
                        value={this.state.form.periode}
                        onChange={this.handleChangeInput}
                        variant="outlined"
                        label="Periode *"
                        labelId={"formperiode"}
                        fullWidth
                      >
                        <MenuItem value="Week">Mingguan</MenuItem>
                        <MenuItem value="Month">Bulanan</MenuItem>
                        <MenuItem value="Semester">Semester</MenuItem>

                      </Select>
                      </FormControl>
                    </Grid>  
                    
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    UPDATE
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbMasterCategoryEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  console.log("state",state)
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbMasterCategoryEdit)));