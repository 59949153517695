import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Checkbox, Card,
  CardMedia, CardContent, Typography, FormControlLabel, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List, ArrowUpward, ArrowDownward } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";

import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';

import DataTable from 'react-data-table-component';

//import actions
import { addParam, deselectParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbMasterServiceType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        keyword: '',
        page: 1,
        count: 100
      },
      serviceList: [],
      serviceListTotal: 0,
      dialogOpen: false,
      loadingTrigger: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
      detailTitle: 'User Detail',
      sortMode: false,
      rerender: 0,
      detailContent: [],
      selectedRows: [],
      mount:false,
    }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {
        return <div>
          {(!this.state.sortMode) &&
            <IconButton color="primary" aria-label="detail" component="span" size="small" style={{ marginRight: 5 }} onClick={() => this.openServiceDetail(row)}>
              <List />
            </IconButton>
          }
          {(!this.state.sortMode) &&
            <IconButton color="primary" aria-label="edit" component="span" size="small" onClick={() => this.editService(row)}>
              <Edit />
            </IconButton>
          }
          {(this.state.sortMode) &&
            <IconButton disabled={row.seq == 1} color="primary" aria-label="edit" component="span" size="small" onClick={() => this.handleMoveUp(row)}>
              <ArrowUpward />
            </IconButton>
          }
          {(this.state.sortMode) &&
            <IconButton disabled={row.seq == this.state.serviceList.length} color="primary" aria-label="edit" component="span" size="small" onClick={() => this.handleMoveDown(row)}>
              <ArrowDownward />
            </IconButton>
          }
          {/* <List style={{ margin: '5px' }} color="action" /> */}
          {/* <Edit style={{ margin: '5px' }} color="action" /> */}
        </div>
      },
    },
    {
      name: <IntlMessages id="master.servicetype.name" />,
      selector: 'name',
      // sortable: true,
    },
    {
      name: <IntlMessages id="master.servicetype.desc" />,
      selector: 'desc',
      // sortable: true,
    },
    {
      name: <IntlMessages id="master.servicetype.timeinput" />,
      selector: 'timeinput',
      // sortable: true,
      cell: row => <span>
        {
          row.timeinput == "true" ? "Ya" : row.timeinput == "" ? "Perlu Edit" : "Tidak"
        }
      </span>
    },
    {
      name: <IntlMessages id="master.servicetype.personinput" />,
      selector: 'personinput',
      // sortable: true,
      cell: row => <span>
        {
          row.personinput == "true" ? "Ya" : row.personinput == "" ? "Perlu Edit" : "Tidak"
        }
      </span>
    },
  ];

  componentDidMount() {
    if (this.props.location.filter) {
      this.setState(
        {
          page: this.props.location.filter, 
        }, () => {
          this.getServiceList();
        }
      )
    } else {
      this.getServiceList();
    }
    this.setState({mount:true})
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({
        dialogOpen: false
      });
      this.props.history.push("/logout");
    } else {

      this.setState({
        dialogOpen: false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteService();
          break;

        default:
          break;
      }
    }
  }

  getServiceList = () => {
    let outer = this;
    this.setState({
      loadingTrigger: true
    });
    axios.post('bo/int/master/servicetype/list', outer.state.page).then(({ data }) => {
      this.setState({
        loadingTrigger: false
      });
      if (data.sta && data.stacod === 200) {
        this.setState({
          serviceList: data.data.list,
          serviceListTotal: data.data.total
        })
      } else {
        outer.showDialog("warning", data.msg);
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  openServiceDetail = (row) => {
    // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function (key) {
      let name = '';
      let seq = 0;
      let view = true;
      switch (key) {
        case 'name':
          name = <IntlMessages id="master.servicetype.name" />;
          seq = 1;
          view = true;
          break;
        case 'desc':
          name = <IntlMessages id="master.servicetype.desc" />;
          seq = 2;
          view = true;
          break;

        default:
          view = false;
          break;
      }
      // console.log("key", key);
      let content = { id: key, name: name, value: row[key], seq: seq, view: view }
      detailContent.push(content);
      // console.log(key, row[key]);

    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen: true,
      detailContent
    });

  }

  editService = (row) => {
    this.props.onAddParam({ detail: row });
    this.props.history.push({ pathname: "/bo/master/servicetype/edit", search: "?i=" + row.id, state:{ filter:this.state.page }});
  }

  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', state.selectedRows);
    this.setState({ selectedRows: state.selectedRows });
  };

  deleteServiceType = () => {
    let serviceLength = this.state.selectedRows.length;
    if (serviceLength == 0) {
      this.showDialog("Peringatan", "Pilih salah satu item untuk menghapus", "");
    } else {
      this.showDialog("Hapus Service", "Anda akan menghapus " + serviceLength + " service type. Apakah anda yakin?", "delete");
    }
  }

  handleMoveUp = (row) => {

    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/master/servicetype/up', { id: row.id }
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getServiceList();
            // outer.showDialog('Information',<IntlMessages id="master.servicetype.success.deleteservice"/>,"");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  handleMoveDown = (row) => {

    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/master/servicetype/down', { id: row.id }
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getServiceList();
            // outer.showDialog('Information',<IntlMessages id="master.servicetype.success.deleteservice"/>,"");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  handleDeleteService = () => {

    const outer = this;
    const { selectedRows } = this.state;
    let deleteService = [];
    selectedRows.forEach((serv) => {
      deleteService.push(serv.id);
    })

    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/master/servicetype/delete', { id: deleteService }
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getServiceList();
            outer.showDialog('Information', <IntlMessages id="master.servicetype.success.deleteservice" />, "");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page:1,
        keyword: val.target.value
      }
    }, () => {
      outer.getServiceList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getServiceList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getServiceList();
    })
  }

  render() {
    const { classes, container, theme, content, title } = this.props;
    const { serviceList } = this.state;


    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>
         <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/master/servicetype/list'>
              Daftar Tipe Pelayanan
            </Link>            
          </Breadcrumbs>
        </div>
        <div className="filter">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              id="outlined-basic"
              label="Search"
              value={this.state.page.keyword}
              variant="outlined"
              size="small"
              style={{ margin: '5px' }}
              onChange={(val) => this.search(val)}
            />
            {/* <IconButton 
                  color="primary" 
                  aria-label="detail" 
                  component="span" 
                  onClick={()=>{this.props.onDeselectParam();this.props.history.push("/bo/master/servicetype/edit")}} 
                >
                    <Add />
                </IconButton>
                <IconButton 
                  color="primary" 
                  aria-label="detail" 
                  component="span" 
                  onClick={()=>{this.deleteServiceType()}} 
                >
                    <Remove />
                </IconButton> */}
            {/* <Link to="/bo/master/user/edit">
                  <Add style={{ margin: '5px' }} color="action" />
                </Link> */}
            {/* <Remove style={{ margin: '5px' }} color="action" /> */}
          </div>
          <div style={{ paddingLeft: 10 }}>
            <FormControlLabel control={<Checkbox
              checked={this.state.sortMode}
              onChange={() => {
                this.setState({ sortMode: !this.state.sortMode, rerender: this.state.rerender + 1 })
              }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              label={""}
            />} label="Ubah Urutan" />
          </div>
        </div>
{(this.state.mount)&&
        <DataTable
          columns={this.columns}
          data={serviceList}
          key={this.state.rerender}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid', marginTop: 10 }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>

          }
          // selectableRows
          // selectableRowsComponent={Checkbox}
          highlightOnHover
          noHeader
          customStyles={customStyles}
          // onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationRowsPerPageOptions={[100,250,500]}
          paginationServer
          paginationTotalRows={this.state.serviceListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          paginationDefaultPage={this.state.page.page}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          progressPending={this.state.loadingTrigger}
          progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100} />
          }
        />
        }
        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          mode={this.state.dialogMode}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
        <DialogDetail
          open={this.state.detailOpen}
          title={this.state.detailTitle}
          content={this.state.detailContent}
          handleClose={this.handleDetailClose}
          handleOk={this.handleDetailClose}
        />
      </div>
    );
  }
}

DbMasterServiceType.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbMasterServiceType)));