import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel, InputLabel,
  Radio, RadioGroup, Button, Dialog, DialogContent, 
  DialogActions, Select, MenuItem, Tooltip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider, KeyboardTimePicker
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";
import moment from 'moment';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

class DbScheduleEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        datestart: "",
        form: {
          id: "",
          day: "",
          timestart:"",
          timestartstr: null,
          timeend:"",
          timeendstr: null,
          title: "",
          description: "",
          servicetypename: "",
          servicetype: "",
          subservicetypename: "",
          subservicetype: ""
          // desc:""
          
        },
        error:{
          day: "",
          timestart:"",
          timeend:"",
          title: "",
          description: "",
          servicetypename: "",
          servicetype: ""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        stpage: {
          keyword: "",
          count: 10
        },
        sstpage: {
          servicetypeid: "",
          keyword: "",
          count: 10
        },
        subservicetypes:[],
        servicetypes:[],
        stobjects: null,
        sstobjects: null
    };

    this.changeServiceType = this.changeServiceType.bind(this);
    this.changeSubServiceType = this.changeSubServiceType.bind(this);
  }

  componentDidMount() {
    this.getServiceType();
  }

  componentDidUpdate(prevprops) {
  
    if(prevprops.selected != this.props.selected) {
      let outer = this;
      if(isEmpty(this.props.selected) != true) {

        this.setState({
          form: {
            ...this.state.form,
            id: this.props.selected.id,
            day: this.props.selected.day,
            timestart: moment(this.props.selected.timestart.replace('Z', '')).format("HH:mm"),
            timestartstr: this.props.selected.startTime,
            timeend: moment(this.props.selected.timeend.replace("Z", "")).format("HH:mm"),
            timeendstr: this.props.selected.endTime,
            title: this.props.selected.name,
            description: this.props.selected.description,
            servicetypename: this.props.selected.servicetypename,
            servicetype: this.props.selected.servicetypeid,
            subservicetypename: this.props.selected.subservicetypename,
            subservicetype: this.props.selected.subservicetypeid
          },
          datestart: this.props.selected.datestart,
          mode: "edit"
        }, () => {
          outer.getEditServiceType();
          outer.getEditSubServiceType();
        })
      }
    }
    // console.log("selected target", this.props.selectedTarget);
    if(prevprops.selectedTarget != this.props.selectedTarget) {
      let outer = this;
      if(this.props.selectedTarget != "") {
        this.setState({
          form : {
            ...this.state.form,
            servicetype: this.props.selectedTarget
          },
          sstpage: {
            ...this.state.sstpage,
            servicetypeid: this.props.selectedTarget
          }
        }, () => {
          outer.getEditServiceType();
          outer.getSubServiceType();
        })
      }else{
        outer.setState({
          stobjects:null,
          stpage: {
            ...this.state.stpage,
            keyword: ''
          },
          subservicetypes:[]
        },()=>{
          outer.getServiceType();
        })
      }
    }
  }

  inputChangeServiceType(val) {
    this.setState({
      stpage: {
        ...this.state.stpage,
        keyword: val
      }
    }, () => {
      this.getServiceType();
    })
  }

  getEditServiceType() {
    let outer = this;
    axios.post('bo/int/master/servicetype', {id: this.state.form.servicetype}).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          stobjects:data.data
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  getServiceType() {
    let outer = this;
    axios.post('bo/int/master/servicetype/list', outer.state.stpage).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          servicetypes:data.data.list
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  changeServiceType(event, val) {
    if(val) {
      if(val != this.state.stobjects){
        this.setState({
          stobjects: val,
          form: {
            ...this.state.form,
            servicetypename: val.name,
            servicetype: val.id,
            subservicetypename: "",
            subservicetype: ""
          },
          sstpage: {
            ...this.state.sstpage,
            keyword: "",
            servicetypeid: val.id
          },
          sstobjects: null,
          subservicetypes:[]
        }, () => {
          this.getSubServiceType();
        })
      }
    } else {
      this.setState({
        stobjects: null,
        form: {
          ...this.state.form,
          servicetypename: "",
          servicetype: "",
          subservicetypename: "",
          subservicetype: ""
        },
        sstpage: {
          ...this.state.sstpage,
          servicetypeid: ""
        },
        sstobjects: null,
        subservicetypes:[]
      })
    }
  }

  inputChangeSubServiceType(val) {
    this.setState({
      sstpage: {
        ...this.state.sstpage,
        keyword: val
      }
    })
  }

  getEditSubServiceType() {
    let outer = this;
    axios.post('bo/int/master/subservicetype', {id: this.state.form.subservicetype}).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          sstobjects:data.data
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  getSubServiceType() {
    let outer = this;
    axios.post('bo/int/master/subservicetype/list', outer.state.sstpage).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          subservicetypes:data.data.list
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  changeSubServiceType(event, val) {
    if(val) {

      this.setState({
        sstobjects: val,
        form: {
          ...this.state.form,
          subservicetypename: val.name,
          subservicetype: val.id
        }
      })
    } else {
      this.setState({
        sstobjects: null,
        form: {
          ...this.state.form,
          subservicetypename: "",
          subservicetype: ""
        }
      })
    }
  }

  

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    }, () => {
      switch(inputName){
        case "mobile":
          if(inputValue){
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;  
        default:
        break;
      }
    });
  }

  handleValidation = (e) => {
    let {form,error,mode,stobjects} = this.state;

    if(form.day === ""){
      error.day=<IntlMessages id="fulltimer.schedule.error.day"/>;
    }else{
      error.day="";
    }

    if(form.timestart === null){
      error.timestart=<IntlMessages id="fulltimer.schedule.error.timestart"/>;
    }else{
      error.timestart="";
    }

    if(form.timeend === null){
      error.timeend=<IntlMessages id="fulltimer.schedule.error.timeend"/>;
    }else{
      error.timeend="";
    }

    if(form.title.trim().length === 0){
      error.title=<IntlMessages id="fulltimer.schedule.error.title"/>;
    }else{
      error.title="";
    }

    if(form.description.trim().length === 0){
      error.description=<IntlMessages id="fulltimer.schedule.error.description"/>;
    }else{
      error.description="";
    }

    if(stobjects === null){
      error.servicetypeid=<IntlMessages id="fulltimer.schedule.error.servicetype"/>;
    }else{
      error.servicetypeid="";
    }

    
    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:{
        ...this.state.form,
        servicetype: stobjects.id
      },
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });
  }

  handleSubmit = () => {
    let {form} = this.state;
    form.day = parseInt(form.day);
    form.timestart = moment(form.timestartstr).format("HH:mm");
    form.timeend = moment(form.timeendstr).format("HH:mm");
    const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        let url = '';
        if(this.state.mode != "add") {
          url = 'bo/ft/user/schedule/edit';
        } else {
          url = 'bo/ft/user/schedule/add';
        }
        axios.post(url, form
        ).then(({data}) => {
          if (data.sta && data.stacod === 200) {
            if(this.state.mode == "add") {
              this.props.addRemaining(data.data);
              outer.setState({
                datestart: "",
                form: {
                  day:"",
                  timestart:null,
                  timeend:null,
                  timestartstr:null,
                  timeendstr:null,
                  title:"",
                  description:"",
                  servicetypename:"",
                  // servicetype: "",
                  subservicetypename: "",
                  subservicetype: ""
                },
                error:{
                  day:"",
                  timestart:"",
                  timeend:"",
                  title:"",
                  description:"",
                  servicetype:""
                },
                mode: "add",
                // stobjects: null,
                sstobjects: null,
                disableBtnSubmit:false
              }, () => {
                outer.props.openInfo('Information', <IntlMessages id="fulltimer.schedule.success.addschedule"/>);
              });
              this.closeDialog();
              this.props.getSchedule();
            } else {
              this.props.addRemaining(data.data);
              this.closeDialog();
              this.props.getSchedule();
            }
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.props.openInfo('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.props.openInfo('Warning',Errormsg["500"]);
        });
      });

  }

  isValidForm = () => {
    return (
      !this.state.error.day &&     
      !this.state.error.timestart &&
      !this.state.error.timeend &&
      !this.state.error.title && 
      !this.state.error.description && 
      !this.state.error.servicetype 
    )
  }

  handleChangeDay = (e) => {
    this.setState({
      form : {
        ...this.state.form,
        day: e.target.value
      }
    })
  }

  handleChangeTime = (e) => {
    this.setState({
      form : {
        ...this.state.form,
        timestartstr: e
      }
    })
  }

  handleChangeTimeEnd = (e) => {
    this.setState({
      form : {
        ...this.state.form,
        timeendstr: e
      }
    })
  }

  closeDialog() {
    this.setState({
      datestart: "",
      form: {
        id: "",
        day: "",
        timestart:"",
        timestartstr: null,
        timeend:"",
        timeendstr: null,
        title: "",
        description: "",
        servicetypename: "",
        // servicetype: "",
        subservicetypename: "",
        subservicetype: ""
        // desc:""
        
      },
      error:{
        day: "",
        timestart:"",
        timeend:"",
        title: "",
        description: "",
        servicetypename: "",
        servicetype: "",
        subservicetypename: "",
        subservicetype: ""
      },
      // stobjects: null,
      sstobjects: null,
      mode: "add"
    });
    this.props.closeModal();
  }

 

  render(){
    const { classes, theme, openEdit } = this.props;
    const { error, servicetypes, subservicetypes } = this.state;
    const title = "Profile"
    return (
      <Dialog
        open={openEdit}
        disableBackdropClick
        maxWidth={"md"}
        fullWidth
        onClose={() => this.props.closeModal()}
        
      >
        <DialogContent>
        
          <Container component="main">
            <CssBaseline />
            <Tooltip
              title="Close"
            >

              <div 
                style={{fontSize: 20, float: 'right', cursor: 'pointer'}}
                onClick={() => this.closeDialog()}
              >
                X
              </div>
            </Tooltip>
            <div className={classes.paper}>
                <form className={classes.form} noValidate>
                  {
                    this.state.datestart != "" &&
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        Tanggal Mulai Jadwal : {this.state.datestart}
                      </Grid>
                    </Grid>
                  }
                    
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            minWidth: '100%',
                            marginBottom: 10
                          }}
                        >
                        <InputLabel id="formday" style={{backgroundColor:"#fff", paddingLeft: 4, paddingRight: 4}} shrink variant="outlined" required>
                          <IntlMessages id="fulltimer.schedule.day"/>
                        </InputLabel>
                        <Select
                          value={this.state.form.day}
                          onChange={this.handleChangeDay}
                          labelId={"formday"}
                          variant="outlined"
                          label="Hari *"
                          fullWidth
                          error={error.day?true:false}
                        >
                          <MenuItem value="0">Minggu</MenuItem>
                          <MenuItem value="1">Senin</MenuItem>
                          <MenuItem value="2">Selasa</MenuItem>
                          <MenuItem value="3">Rabu</MenuItem>
                          <MenuItem value="4">Kamis</MenuItem>
                          <MenuItem value="5">Jumat</MenuItem>
                          <MenuItem value="6">Sabtu</MenuItem>

                        </Select>
                        {
                          error.day && 
                          <FormControlLabel
                            classes={{label:classes.labelerror}}
                            control={<div>&nbsp;&nbsp;&nbsp;</div>}
                            label={error.day}
                          />
                        }
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          {/* <InputLabel id="formday">
                            <IntlMessages id="fulltimer.schedule.timestart"/><span> *</span>
                          </InputLabel> */}
                          <KeyboardTimePicker
                            fullWidth
                            id="timestart"
                            name="timestart"
                            label={<IntlMessages id="fulltimer.schedule.timestart"/>}
                            required
                            inputVariant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={this.state.form.timestartstr}
                            onChange={this.handleChangeTime}
                            format="HH:mm"
                            KeyboardButtonProps={{
                              'aria-label': 'Ganti waktu',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          {/* <InputLabel id="formday">
                            <IntlMessages id="fulltimer.schedule.timeend"/><span> *</span>
                          </InputLabel> */}
                          <KeyboardTimePicker
                            fullWidth
                            id="timeend"
                            name="timeend"
                            label={<IntlMessages id="fulltimer.schedule.timeend"/>}
                            required
                            inputVariant="outlined"
                            value={this.state.form.timeendstr}
                            onChange={this.handleChangeTimeEnd}
                            format="HH:mm"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'Ganti waktu',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12}>
                      {/* <InputLabel id="title">
                        <IntlMessages id="fulltimer.schedule.title"/><span> *</span>
                      </InputLabel> */}
                      <TextField
                        id="title"
                        name="title"
                        variant="outlined"
                        label={<IntlMessages id="fulltimer.schedule.title"/>}
                        value={this.state.form.title} 
                        size="small"
                        required
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.title?true:false}
                      />
                        {
                            error.title &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.title}
                              />
                        }
                      </Grid> 
                      <Grid item xs={12}>
                        {/* <InputLabel id="description">
                          <IntlMessages id="fulltimer.schedule.description"/><span> *</span>
                        </InputLabel> */}
                        <TextField
                          id="description"
                          name="description"
                          variant="outlined"
                          label={<IntlMessages id="fulltimer.schedule.description"/>}
                          value={this.state.form.description} 
                          size="small"
                          required
                          fullWidth
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error.description?true:false}
                        />
                          {
                              error.description &&   
                                <FormControlLabel
                                  classes={{label:classes.labelerror}}
                                  control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                  label={error.description}
                                />
                          }
                      </Grid> 
                                       
                      <Grid item xs={12}>
                        {/* <InputLabel id="servicetype">
                          <IntlMessages id="fulltimer.schedule.servicetype"/><span> *</span>
                        </InputLabel> */}
                        <Autocomplete
                          options={servicetypes}
                          getOptionLabel={option => option.name}
                          value={this.state.stobjects}
                          onFocus={(e)=>{this.inputChangeServiceType('')}}
                          onChange={this.changeServiceType}
                          onInputChange={(event, val) => this.inputChangeServiceType(val)}
                          renderInput={
                            param => (
                              <TextField 
                                {...param}
                                id="servicetype" 
                                fullWidth
                                required
                                label={<IntlMessages id="fulltimer.schedule.servicetype"/>}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={error.servicetypeid?true:false}
                              />
                            )
                          }
                        />
                        {
                            error.servicetypeid &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.servicetypeid}
                              />
                        }
                      </Grid>                    
                      <Grid item xs={12}>
                        {/* <InputLabel id="subservicetype">
                          <IntlMessages id="fulltimer.schedule.subservicetype"/>
                        </InputLabel> */}
                        <Autocomplete
                          options={subservicetypes}
                          getOptionLabel={option => option.name}
                          value={this.state.sstobjects}
                          onFocus={(e)=>{this.inputChangeSubServiceType('')}}
                          onChange={this.changeSubServiceType}
                          onInputChange={(event, val) => this.inputChangeSubServiceType(val)}
                          renderInput={
                            param => (
                              <TextField 
                                {...param}
                                id="subservicetype" 
                                variant="outlined"
                                size="small"
                                label={<IntlMessages id="fulltimer.schedule.subservicetype"/>}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )
                          }
                        />
                      </Grid>                    
        
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={
                          this.state.mode != "add" ?
                          6 : 12
                          }>
                          <Button
                            type="button"
                            className={classes.submit}
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={this.handleValidation}
                            // disabled={this.state.disableBtnSubmit}
                          >
                            {
                              this.state.mode != "add" ?
                              "UPDATE" : "SUBMIT"
                            }
                          </Button>
                        </Grid>
                        {
                          this.state.mode != "add" ?
                          <Grid item xs={6}>
                            <Button
                              type="button"
                              className={classes.submit}
                              variant="contained"
                              style={{backgroundColor: 'red', color: 'white'}}
                              fullWidth
                              onClick={() => {
                                this.closeDialog();
                                this.props.delSchedule(this.state.form.id);
                              }}
                              // disabled={this.state.disableBtnSubmit}
                            >
                              DELETE
                            </Button>
                          </Grid> : null
                        }
                        
                    </Grid>
                    
                    
                  </form>
            </div>
          </Container>
        </DialogContent> 

         
      </Dialog>
    );
  }
}

DbScheduleEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  console.log("state",state)
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbScheduleEdit)));