import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, 
  CardMedia ,CardContent, Typography, Breadcrumbs, Link} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {MoodBad} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";

import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';

import DataTable from 'react-data-table-component';

//import actions
import { addParam, deselectParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions:[10,25,100],
  print:false,
  download:false,
  selectableRows:'single',
  isRowSelectable:function(){return false}
};

class DbMasterSubCategory extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page:{
          categoryname: '',
          categoryid: '',
          keyword: '',
          page:1,
          count:100
        },
        subcategoryList:[],
        loadingTrigger:false,
        subcategoryListTotal: 0,
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
        detailTitle:'Detil Sub Kategori',
        catpage: {
          keyword: "",
          count: 10
        },
        catobject: null,
        categories: [],
        detailContent:[],
        selectedRows:[],
        mount:false,
      }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {return <div>
        <IconButton color="primary" aria-label="detail" component="span" size="small" style={{marginRight:5}} onClick={()=>this.openSubCategoryDetail(row)}>
            <List />
        </IconButton>
        <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.editSubCategory(row)}>
            <Edit />
        </IconButton>
        {/* <List style={{ margin: '5px' }} color="action" /> */}
        {/* <Edit style={{ margin: '5px' }} color="action" /> */}
      </div>},
    },
    {
      name: <IntlMessages id="master.subcategory.category"/>,
      selector: 'categoryname',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.subcategory.name"/>,
      selector: 'name',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.subcategory.desc"/>,
      selector: 'desc',
      sortable: true,
      hide: 'sm'
    },
  ];

  componentDidMount(){
    if (this.props.location.filter) {
      this.setState(
        {
          page: this.props.location.filter, 
        }, () => {
          this.getSubCategoryList();
        }
      )
    } else {
      this.getSubCategoryList();
    }
    this.setState({mount:true})
    this.getCategory();
  }

  inputChangeCategory(val) {
    this.setState({
      catpage: {
        ...this.state.catpage,
        keyword: val
      }
    }, () => {
      this.getCategory();
    })
  }

  getCategory() {
    let outer = this;
    axios.post('bo/int/master/category/list', outer.state.catpage).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          categories:data.data.list
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  changeCategory = (event, val) => {
    if(val) {

      this.setState({
        catobject: val,
        page: {
          ...this.state.page,
          categoryname: val.name,
          categoryid: val.id,
          page: 1,
          count: 10
        }
      }, () => {
        this.getSubCategoryList();
      })
    } else {
      this.setState({
        catobject: null,
        page: {
          ...this.state.page,
          categoryname: "",
          categoryid: "",
          page: 1,
          count: 10
        }
      }, () => {
        this.getSubCategoryList();
      })
    }
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {

      this.setState({
        dialogOpen:false
      });
      this.props.history.push("/logout");
    } else {

      this.setState({
        dialogOpen:false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteSubCategory();
          break;
      
        default:
          break;
      }
    }
  }

  getSubCategoryList = () => {
    let outer = this;
    this.setState({
      loadingTrigger:true
    });
    axios.post('bo/int/master/subcategory/list', outer.state.page).then(({data})=>{
      this.setState({
        loadingTrigger:false
      });
      if(data.sta && data.stacod === 200){
        this.setState({
          subcategoryList: data.data.list,
          subcategoryListTotal: data.data.total
        })
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  openSubCategoryDetail = (row) => {
    // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function(key) {
      let name = '';
      let seq = 0;
      let view = true;
      switch (key) {
        case 'categoryname':
          name = <IntlMessages id="master.subcategory.category"/>;
          seq = 1;
          view = true;
          break;
        case 'name':
          name = <IntlMessages id="master.subcategory.name"/>;
          seq = 2;
          view = true;
          break;
        case 'desc':
          name = <IntlMessages id="master.subcategory.desc"/>;
          seq = 3;
          view = true;
          break;
      
        default:
          view = false;
          break;
      }
      console.log("key", key);
      let content = {id:key, name:name, value:row[key], seq: seq, view:view}
      detailContent.push(content);
      // console.log(key, row[key]);
    
    });
    detailContent.sort(compareSequence);
    console.log(detailContent);
    this.setState({
      detailOpen:true,
      detailContent
    });

  }

  editSubCategory = (row) => {
    this.props.onAddParam({detail:row});
    this.props.history.push({pathname:"/bo/master/subcategory/edit",search:"?i="+row.id,state:{ filter:this.state.page, filterAll:this.state.catobject}});
  }
 
  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', state.selectedRows);
    this.setState({selectedRows:state.selectedRows});
  };

  deleteSubCategory = () => {
    let subcategoryLength = this.state.selectedRows.length;
    if(subcategoryLength == 0) {
      this.showDialog("Peringatan","Pilih salah satu item untuk menghapus","");  
    } else {
      this.showDialog("Hapus Sub Kategori","Anda akan menghapus "+ subcategoryLength+" sub kategori. Apakah anda yakin?","delete");
    }
  }
   
  handleDeleteSubCategory = () => {
    
    const outer = this;
    const {selectedRows} = this.state;
    let deleteSubcategory = [];
    selectedRows.forEach((sub)=>{
      deleteSubcategory.push(sub.id);
    })
    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/master/subcategory/delete', {id:deleteSubcategory}
      ).then(({data}) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit:false
          }, () => {
            outer.getSubCategoryList();
            outer.showDialog('Information',<IntlMessages id="master.subcategory.success.deletesubcategory"/>,"");
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page:1,
        keyword: val.target.value,
      }
    }, () => {
      outer.getSubCategoryList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        categoryid: '',
        categoryname: '',
        count: val
      }
    }, () => {
      outer.getSubCategoryList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getSubCategoryList();
    })
  }
   
  render() {
    const { classes, container, theme, content, title } = this.props;
    const { subcategoryList, categories} = this.state;
    
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
        <div style={{paddingTop:10}}>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/master/subcategory/list'>
              Daftar Sub Kategori
            </Link>            
          </Breadcrumbs>
        </div>
        <div className="filter">
          <Autocomplete
              options={categories}
              getOptionLabel={option => option.name}
              value={this.state.catobject}
              onChange={this.changeCategory}
              onFocus={(e)=>{this.inputChangeCategory('')}}
              onInputChange={(event, val) => this.inputChangeCategory(val)}
              renderInput={
                param => (
                  <TextField 
                    {...param}
                    id="outlined-basic"
                    // variant="outlined" 
                    label="Kategori"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <TextField 
              id="outlined-basic" 
              label="Search" 
              variant="outlined" 
              value={this.state.page.keyword}
              size="small" 
              style={{ margin: '5px' }}
              onChange={(val) => this.search(val)} 
            />
            
            {/* <IconButton 
              color="primary" 
              aria-label="detail" 
              component="span" 
              onClick={()=>{this.props.onDeselectParam();this.props.history.push("/bo/master/subservicetype/edit")}} 
            >
                <Add />
            </IconButton>
            <IconButton 
              color="primary" 
              aria-label="detail" 
              component="span" 
              onClick={()=>{this.deleteServiceType()}} 
            >
                <Remove />
            </IconButton> */}
          </div>
         </div>
         
{(this.state.mount)&&
         <DataTable
          columns={this.columns}
          data={subcategoryList}
          noDataComponent={
            <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid', marginTop: 10}}>
              <CardMedia style={{textAlign: 'center'}}>
                <MoodBad color="action" style={{
                  fontSize: 40, 
                  marginTop: 10, 
                  marginBottom: 10
                }}/>
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>
            
          }
          // selectableRows
          // selectableRowsComponent={Checkbox}
          highlightOnHover
          customStyles={customStyles}
          // onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationRowsPerPageOptions={[100,250,500]}
          paginationServer
          paginationDefaultPage={this.state.page.page}
          // overflowYOffset="50px"
          paginationTotalRows={this.state.subcategoryListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          noHeader
          progressPending={ this.state.loadingTrigger}
progressComponent={
<Loader type="TailSpin" color="#204196" height={100} width={100}/>
}
        />
  }
          <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            mode={this.state.dialogMode}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
          <DialogDetail
            open={this.state.detailOpen} 
            title={this.state.detailTitle}
            content={this.state.detailContent}
            handleClose={this.handleDetailClose}
            handleOk={this.handleDetailClose}
            />  
      </div>
    );
  }
}

DbMasterSubCategory.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbMasterSubCategory)));