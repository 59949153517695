import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, CssBaseline, Drawer, Hidden,
  IconButton, Divider, Toolbar, Typography,
  withStyles, useTheme, withTheme, fade,
  Container, InputBase, MenuItem, Menu,
  Button
} from '@material-ui/core';
import {SearchIcon, ExitToApp} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from "react-redux";

import Dashboardlogo from '../component/dashboardlogo';
import DashboardProfile from '../component/dashboardprofile';
import AdminListItems from '../component/drawerlistadmin';
import PrivateRoute from '../component/privateroute';
import { Redirect } from "react-router-dom";
import DbHomeAdmin from './dbahome';
import DbHomeFt from './dbahomeft';
import DbHomePenatua from './dbahomepenatua';
import DbHomePage from './dbapage';
import DbMasterUser from './pages/master/user/list/index';
import DbMasterUserEdit from './pages/master/user/edit/index';
import DbMasterPenatua from './pages/master/penatua/list/index';
import DbMasterPenatuaEdit from './pages/master/penatua/edit/index';
import DbMasterFulltimer from './pages/master/fulltimer/list/index';
import DbMasterFulltimerEdit from './pages/master/fulltimer/edit/index';
import DbMasterServiceType from './pages/master/servicetype/list/index';
import DbMasterServiceTypeEdit from './pages/master/servicetype/edit/index';
import DbMasterSubServiceType from './pages/master/subservicetype/list/index';
import DbMasterSubServiceTypeEdit from './pages/master/subservicetype/edit/index';
import DbMasterCategory from './pages/master/category/list/index';
import DbMasterCategoryEdit from './pages/master/category/edit/index';
import DbMasterSubCategory from './pages/master/subcategory/list/index';
import DbMasterSubCategoryEdit from './pages/master/subcategory/edit/index';
import DbMasterType from './pages/master/type/list/index';
import DbMasterTypeEdit from './pages/master/type/edit/index';
import DbMasterLokal from './pages/master/lokal/list/index';
import DbMasterLokalEdit from './pages/master/lokal/edit/index';
import DbMasterCluster from './pages/master/cluster/list/index';
import DbMasterClusterEdit from './pages/master/cluster/edit/index';
import DbSettingShepherding from './pages/setting/shepherding/list/index';
import DbSettingShepherdingEdit from './pages/setting/shepherding/edit/index';
import DbSettingTarget from './pages/setting/target/list/index';
import DbSettingTargetEdit from './pages/setting/target/edit/index';
import DbSettingTargetPeriode from './pages/setting/targetperiode/list/index';
import DbSettingTargetPeriodeEdit from './pages/setting/targetperiode/edit/index';
import DbSettingInput from './pages/setting/input/list/index';
import DbSettingInputEdit from './pages/setting/input/edit/index';
import DbSettingUsrDefaultConv from './pages/setting/usrtargetconv/list/index';
import DbSettingUsrDefaultConvEdit from './pages/setting/usrtargetconv/edit/index';
import DbSettingDefaultConvEdit from './pages/setting/deftargetconv/index';
import DbSettingDriveConnect from './pages/setting/driveconnect/index';
import DbReportTask from './pages/report/task/list/index';
import DbReportFullTask from './pages/report/fulltask/list/index';
import DbReportAnugerahBible from './pages/report/anugerahbible/list/index';
import DbReportAnugerahBibleWeek from './pages/report/anugerahbibleweek/list/index';
import DbReportServiceTimeUser from './pages/report/servicetimeuser/list/index';
import DbReportServiceTimeUserWeek from './pages/report/servicetimeuserweek/list/index';
import DbReportNonPenggembalaan from './pages/report/nonpenggembalaan/list/index';
import DbReportNonPenggembalaanWeek from './pages/report/nonpenggembalaanweek/list/index';
import DbReportNPSchedule from './pages/report/npschedule/list/index';
import DbReportNPDetail from './pages/report/npdetail/list/index';
import DbReportNPAllUser from './pages/report/npalluser/list/index';
import pengumpulanTugas from './pages/report/pengumpulanTugas/list/index';
import laporanrekapjadwal from './pages/report/laporanRekapJadwal/list/index';
import DbReportBaptisWeek from './pages/report/baptis/list/index';
import DbReportVitalGroupWeek from './pages/report/vitalgroup/list/index';
import DbReportVitalWeek from './pages/report/vital/list/index';
import DbReportSprWeek from './pages/report/spr/list/index';
import DbReportJala from './pages/report/jala/list/index';
import DbReportJalaWeek from './pages/report/jalaweek/list/index';
import DbExportDaily from './pages/export/daily/index';
import DbReportAnugerahBibleft from './pages/fulltimer/anugerahbible/list/index';
import DbReportServiceTimeft from './pages/fulltimer/servicetimeuser/list/index';
import DbReportNonPenggembalaanft from './pages/fulltimer/nonpenggembalaan/list/index';
import DbReportJalaft from './pages/fulltimer/jala/list/index';
import DbReportBaptisft from './pages/fulltimer/baptis/list/index';
import DbReportSprft from './pages/fulltimer/spr/list/index';
import DbReportVitalGroupft from './pages/fulltimer/vitalgroup/list/index';
import DbReportVitalft from './pages/fulltimer/vital/list/index';
import DbPenuntutanFT from './pages/fulltimer/penuntutanft/index';
import DbProfileEdit from './pages/profile/index';
import DbSchedule from './pages/fulltimer/schedule/list/index';
import DbScheduleActive from './pages/fulltimer/activeschedule/list/index';
import DbFulltimerTargetEdit from './pages/fulltimer/fttarget/edit/index';
import DbFulltimerTargetPeriodeEdit from './pages/fulltimer/fttargetperiode/edit/index';
import {
  BrowserRouter as Router,Route,Switch
} from "react-router-dom";
import axios from '../config/axios';

import { Role } from '../object/role';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo:{
    minHeight: 52
  },
  toolbar: {
    padding: '0px 16px 16px 16px',
    // overflow: "auto",
    flexGrow: 1
  },
  profile: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center'
  },
  sidebar:{
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  dbcontainer:{
    paddingLeft:0,
    paddingRight:0,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 'calc(100% - 100)',
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '500px',
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: '10px',
      width: '350px',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  costumToolbar: {
    minHeight: 52,
    backgroundColor: "#0b56a5"
  },
  organization: {
    position: 'absolute',
    right: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    color: '#fff' 
  }
});

class DashboardAdmin extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      mobileOpen:false,
      anchorEl: null
    }
    // window.addEventListener('resize', this.update)
  }

  update = () => {
    // console.log("window.innerWidth", window.innerWidth)
  }

  componentDidMount() {
    // let dataprops = this.props.token;
    // axios.defaults.headers.common['Authorization'] = "Bearer " + dataprops.token.tkn;
  }

  

  handleClick = event => {
    // console.log("anchor",event.currentTarget);
    
    // this.setState({anchorEl:event.currentTarget});
    this.props.history.push("/logout")
  };
  
  handleClose = () => {
    this.setState({anchorEl:null});
  };

  validUser() {
    let res = true;
    // if(window){
    //   if (localStorage.getItem("uid") === null || 
    //   localStorage.getItem("email") === null || 
    //   localStorage.getItem("tu") === null || 
    //   localStorage.getItem("pict") === null || 
    //   localStorage.getItem("tkn") === null || 
    //   localStorage.getItem("tknref") === null ) {
    //     localStorage.removeItem("uid");
    //     localStorage.removeItem("email");
    //     localStorage.removeItem("tu");
    //     localStorage.removeItem("pict");
    //     localStorage.removeItem("tkn");
    //     localStorage.removeItem("tknref");
    //     res= false;
    //   }else{
    //     if(localStorage.getItem("tu") == 'User'){
    //       localStorage.removeItem("uid");
    //       localStorage.removeItem("email");
    //       localStorage.removeItem("tu");
    //       localStorage.removeItem("pict");
    //       localStorage.removeItem("tkn");
    //       localStorage.removeItem("tknref");
    //       res= false;
    //     }
    //   }
    // }
    return res;
  }

  handleDrawerToggle = () => {
    this.setState({
      mobileOpen:!this.state.mobileOpen
    })
  };

  render(){
    const { classes, container, theme, content, title, token } = this.props;
    const { mobileOpen } = this.state;
    const drawer = (
      <div className={classes.sidebar}>
        <div className={classes.toolbar} >
          <div className={classes.logo}>
            <Dashboardlogo mobile={false} role={token.token.role} refuser="admin" />
          </div>
          <AdminListItems mobile={false} />
        </div>
        <Divider />
        <div className={classes.profile} >
          <DashboardProfile refuser="admin" role={token.token.role} mobile={false} />
        </div>
      </div>
    );

    const drawerMobile = (
      <div className={classes.sidebar}>
        <div className={classes.toolbar} >
          <div className={classes.logo}>
            <Dashboardlogo mobile={true} role={token.token.role} closeDrawer={() => this.handleDrawerToggle()} refuser="admin" />
          </div>
          <AdminListItems mobile={true} closeDrawer={() => this.handleDrawerToggle()}/>
        </div>
        <Divider />
        <div className={classes.profile} >
          <DashboardProfile mobile={true} role={token.token.role} closeDrawer={() => this.handleDrawerToggle()} refuser="admin" />
        </div>
      </div>
    );


    if(this.validUser()){
      return (
        <div className={classes.root} aaa="aaa">
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.costumToolbar}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {title} 
              </Typography>
              {/* <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div> */}
              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick} className={classes.organization}>
                  <ExitToApp/>{" "}Logout
              </Button>
                {/* <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose}>Org 1</MenuItem>
                  <MenuItem onClick={this.handleClose}>Org 2</MenuItem>
                  <MenuItem onClick={this.handleClose}>Org 3</MenuItem>
                </Menu> */}
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawerMobile}
              </Drawer>
            </Hidden>
            {/* {
              window.innerWidth < 769 &&
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawerMobile}
              </Drawer>
            } */}
            <Hidden smDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
            {/* {
              window.innerWidth > 768 &&
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            } */}
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Container fixed component="main" maxWidth="md" className={classes.dbcontainer}>
              <PrivateRoute exact path="/bo/dashboard" roles={[Role.Administrator, Role.Internal]} component={DbHomeAdmin} />
              <PrivateRoute exact path="/bo/dashboardft" roles={[Role.User]} component={DbHomeFt} />
              <PrivateRoute exact path="/bo/dashboardpenatua" roles={[Role.Penatua]} component={DbHomePenatua} />
              <PrivateRoute exact path="/bo/adminpage" roles={[Role.Admin]} component={DbHomePage} />
              {/* Administrator & Penatua (half) */}
              <PrivateRoute exact path="/bo/master/user/list" roles={[Role.Administrator, Role.Internal]} component={DbMasterUser} />
              <PrivateRoute exact path="/bo/master/user/edit" roles={[Role.Administrator, Role.Internal]} component={DbMasterUserEdit} />
              <PrivateRoute exact path="/bo/master/fulltimer/list" roles={[Role.Administrator, Role.Internal]} component={DbMasterFulltimer} />
              <PrivateRoute exact path="/bo/master/fulltimer/edit" roles={[Role.Administrator, Role.Internal]} component={DbMasterFulltimerEdit} />
              <PrivateRoute exact path="/bo/master/penatua/list" roles={[Role.Administrator, Role.Internal]} component={DbMasterPenatua} />
              <PrivateRoute exact path="/bo/master/penatua/edit" roles={[Role.Administrator, Role.Internal]} component={DbMasterPenatuaEdit} />
              <PrivateRoute exact path="/bo/master/servicetype/list" roles={[Role.Administrator, Role.Internal]} component={DbMasterServiceType} />
              <PrivateRoute exact path="/bo/master/servicetype/edit" roles={[Role.Administrator, Role.Internal]} component={DbMasterServiceTypeEdit} />
              <PrivateRoute exact path="/bo/master/subservicetype/list" roles={[Role.Administrator, Role.Internal]} component={DbMasterSubServiceType} />
              <PrivateRoute exact path="/bo/master/subservicetype/edit" roles={[Role.Administrator, Role.Internal]} component={DbMasterSubServiceTypeEdit} />
              <PrivateRoute exact path="/bo/master/category/list" roles={[Role.Administrator, Role.Internal]} component={DbMasterCategory} />
              <PrivateRoute exact path="/bo/master/category/edit" roles={[Role.Administrator, Role.Internal]} component={DbMasterCategoryEdit} />
              <PrivateRoute exact path="/bo/master/subcategory/list" roles={[Role.Administrator, Role.Internal]} component={DbMasterSubCategory} />
              <PrivateRoute exact path="/bo/master/subcategory/edit" roles={[Role.Administrator, Role.Internal]} component={DbMasterSubCategoryEdit} />
              <PrivateRoute exact path="/bo/master/type/list" roles={[Role.Administrator, Role.Internal]} component={DbMasterType} />
              <PrivateRoute exact path="/bo/master/type/edit" roles={[Role.Administrator, Role.Internal]} component={DbMasterTypeEdit} />
              <PrivateRoute exact path="/bo/master/lokal/list" roles={[Role.Administrator, Role.Internal]} component={DbMasterLokal} />
              <PrivateRoute exact path="/bo/master/lokal/edit" roles={[Role.Administrator, Role.Internal]} component={DbMasterLokalEdit} />
              <PrivateRoute exact path="/bo/master/cluster/list" roles={[Role.Administrator, Role.Internal]} component={DbMasterCluster} />
              <PrivateRoute exact path="/bo/master/cluster/edit" roles={[Role.Administrator, Role.Internal]} component={DbMasterClusterEdit} />
              <PrivateRoute exact path="/bo/setting/shepherding/list" roles={[Role.Administrator, Role.Internal]} component={DbSettingShepherding} />
              <PrivateRoute exact path="/bo/setting/shepherding/edit" roles={[Role.Administrator, Role.Internal]} component={DbSettingShepherdingEdit} />
              <PrivateRoute exact path="/bo/setting/target/list" roles={[Role.Administrator, Role.Internal]} component={DbSettingTarget} />
              <PrivateRoute exact path="/bo/setting/target/edit" roles={[Role.Administrator, Role.Internal]} component={DbSettingTargetEdit} />
              <PrivateRoute exact path="/bo/setting/targetperiode/list" roles={[Role.Administrator, Role.Internal]} component={DbSettingTargetPeriode} />
              <PrivateRoute exact path="/bo/setting/targetperiode/edit" roles={[Role.Administrator, Role.Internal]} component={DbSettingTargetPeriodeEdit} />
              <PrivateRoute exact path="/bo/setting/input/list" roles={[Role.Administrator, Role.Internal]} component={DbSettingInput} />
              <PrivateRoute exact path="/bo/setting/input/edit" roles={[Role.Administrator, Role.Internal]} component={DbSettingInputEdit} />
              <PrivateRoute exact path="/bo/setting/usrdefconv/list" roles={[Role.Administrator, Role.Internal]} component={DbSettingUsrDefaultConv} />
              <PrivateRoute exact path="/bo/setting/usrdefconv/edit" roles={[Role.Administrator, Role.Internal]} component={DbSettingUsrDefaultConvEdit} />
              <PrivateRoute exact path="/bo/setting/defaultconv" roles={[Role.Administrator, Role.Internal]} component={DbSettingDefaultConvEdit} />
              <PrivateRoute exact path="/bo/setting/driveconn" roles={[Role.Administrator, Role.Internal]} component={DbSettingDriveConnect} />
              {/* <PrivateRoute exact path="/bo/report/task/list" roles={[Role.Administrator, Role.Internal]} component={DbReportTask} />
              <PrivateRoute exact path="/bo/report/fulltask/list" roles={[Role.Administrator, Role.Internal]} component={DbReportFullTask} /> */}
              <PrivateRoute exact path="/bo/report/bible/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportAnugerahBible} />
              <PrivateRoute exact path="/bo/report/bibleweek/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportAnugerahBibleWeek} />
              <PrivateRoute exact path="/bo/report/servicetimeuser/list"  roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportServiceTimeUser} />
              <PrivateRoute exact path="/bo/report/servicetimeuserweek/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportServiceTimeUserWeek} />
              <PrivateRoute exact path="/bo/report/nonshepherd/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportNonPenggembalaan} />
              <PrivateRoute exact path="/bo/report/nonshepherdweek/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportNonPenggembalaanWeek} />
              <PrivateRoute exact path="/bo/report/nonshepherd/detail" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportNPDetail} />
              <PrivateRoute exact path="/bo/report/nonshepherd/alluser" roles={[Role.Administrator, Role.Internal]} component={DbReportNPAllUser} />
              <PrivateRoute exact path="/bo/report/nonshepherd/schedule" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportNPSchedule} />
              <PrivateRoute exact path="/bo/report/pengumpulantugas/alluser" roles={[Role.Administrator, Role.Internal]} component={pengumpulanTugas} />
              <PrivateRoute exact path="/bo/report/jala/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportJala} />
              <PrivateRoute exact path="/bo/report/jalaweek/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportJalaWeek} />
              <PrivateRoute exact path="/bo/report/baptis/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportBaptisWeek} />
              <PrivateRoute exact path="/bo/report/vitalgroup/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportVitalGroupWeek} />
              <PrivateRoute exact path="/bo/report/vital/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportVitalWeek} />
              <PrivateRoute exact path="/bo/report/spr/list" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbReportSprWeek} />
              <PrivateRoute exact path="/bo/export/daily" roles={[Role.Administrator, Role.Internal, Role.Penatua]} component={DbExportDaily} />
              <PrivateRoute exact path="/bo/report/laporanrekapjadwal/alluser" roles={[Role.Administrator, Role.Internal]} component={laporanrekapjadwal} />
              <PrivateRoute exact path="/bo/profile/edit" roles={[Role.Administrator, Role.Internal, Role.User, Role.Penatua]} component={DbProfileEdit} />
              {/* User */}
              <PrivateRoute exact path="/bo/schedule/list" roles={[Role.User]} component={DbSchedule} />
              <PrivateRoute exact path="/bo/schedule/active/list" roles={[Role.User]} component={DbScheduleActive} />
              <PrivateRoute exact path="/bo/target/edit" roles={[Role.User]} component={DbFulltimerTargetEdit} />
              <PrivateRoute exact path="/bo/targetperiode/edit" roles={[Role.User]} component={DbFulltimerTargetPeriodeEdit} />
              <PrivateRoute exact path="/bo/penuntutanft" roles={[Role.User]} component={DbPenuntutanFT} />
              <PrivateRoute exact path="/bo/ft/report/bible/list" roles={[Role.User]} component={DbReportAnugerahBibleft} />
              <PrivateRoute exact path="/bo/ft/report/servicetimeuser/list" roles={[Role.User]} component={DbReportServiceTimeft} />
              <PrivateRoute exact path="/bo/ft/report/nonshepherd/list" roles={[Role.User]} component={DbReportNonPenggembalaanft} />
              <PrivateRoute exact path="/bo/ft/report/jala/list" roles={[Role.User]} component={DbReportJalaft} />
              <PrivateRoute exact path="/bo/ft/report/baptis/list" roles={[Role.User]} component={DbReportBaptisft} />
              <PrivateRoute exact path="/bo/ft/report/spr/list" roles={[Role.User]} component={DbReportSprft} />
              <PrivateRoute exact path="/bo/ft/report/vitalgroup/list" roles={[Role.User]} component={DbReportVitalGroupft} />
              <PrivateRoute exact path="/bo/ft/report/vital/list" roles={[Role.User]} component={DbReportVitalft} />
              {/* <Route path="/bo/dashadmin">
                <DbHomeAdmin />
              </Route>
              <Route path="/bo/adminpage">
                <DbHomePage />
              </Route>
              <Route path="/bo/master/user/list">
                <DbMasterUser />
              </Route>
              <Route path="/bo/master/user/edit">
                <DbMasterUserEdit />
              </Route> */}
            </Container>
      
          </main>
        </div>
      );
    }else{
      const path="/";
      return <Redirect to={path} />
    }
  }
}

DashboardAdmin.propTypes = {
  classes: PropTypes.object.isRequired,

  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
}
const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStatestoProps
)(withStyles(styles)(withTheme(DashboardAdmin)));