import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Checkbox, Card, Tooltip, Chip,
  CardMedia, CardContent, Typography, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List, Power, PowerOff } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import moment from 'moment';
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';

import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';

import DataTable from 'react-data-table-component';

//import actions
import { addParam, deselectParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [100, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbMasterFulltimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        search: '',
        page: 1,
        results: 100,
        count:100,
        lokalid: "",
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      userList: [],
      userListTotal: 0,
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
      detailTitle: 'Detil User',
      selectedUsrActive: {},
      detailContent: [],
      selectedRows: [],
      locallist: [],
      selectedLocal: {},
      loadingTrigger: false,
      mount:false,
    }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {
        return <div>
          <IconButton color="primary" aria-label="detail" component="span" size="small" style={{ marginRight: 5 }} onClick={() => this.openUserDetail(row)}>
            <List />
          </IconButton>
          <IconButton color="primary" aria-label="edit" component="span" size="small" onClick={() => this.editUser(row)}>
            <Edit />
          </IconButton>
          <IconButton color="primary" aria-label="edit" component="span" size="small" onClick={() => this.warningNonActiv(row)}>
            {
              row.active == "Active" ?
                <Tooltip title="Non Aktifkan">
                  <PowerOff />
                </Tooltip> :
                <Tooltip title="Aktifkan">
                  <Power />
                </Tooltip>
            }
          </IconButton>
          {/* <List style={{ margin: '5px' }} color="action" /> */}
          {/* <Edit style={{ margin: '5px' }} color="action" /> */}
        </div>
      },
    },
    {
      name: <IntlMessages id="master.user.firstname" />,
      selector: 'firstname',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.user.lastname" />,
      selector: 'lastname',
      sortable: true,
    },
    {
      name: "Lokal Fulltimer",
      selector: 'lokal.name',
      sortable: true,
    },
    {
      name: "Lokal Penatua",
      selector: 'shepherding.shepherdlocal',
      sortable: true,
    },
    {
      name: "Wilayah Penatua",
      selector: 'shepherding.shepherdcluster',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.user.gender" />,
      selector: 'gender',
      sortable: true,
      hide: 'sm',
    },
    // {
    //   name: <IntlMessages id="master.user.birthdate" />,
    //   selector: 'birthdatestr',
    //   sortable: true,
    //   hide: 'sm',
    // },
    {
      name: <IntlMessages id="master.user.active" />,
      selector: 'active',
      sortable: true,
      cell: row => {
        return (
          <Chip label={row.active == "Active" ? "Aktif" : "Tidak Aktif"} color={row.active == "Active" ? "secondary" : "default"} />
        )
      }
    },
  ];

  componentDidMount() {
    if (this.props.location.filter) {
      this.setState(
        { page: this.props.location.filter, selectedLocal: this.props.location.selectedLokal}, () => { this.getUserList() }
      )
    } else {
      this.getUserList();
    }
    this.getLocal();
    this.setState({mount:true});
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    });
    if (this.state.dialogMode == "nonactive") {
      this.setState({
        selectedUsrActive: {}
      })
    }
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({
        dialogOpen: false
      });
      this.props.history.push("/logout");
    } else {
      this.setState({
        dialogOpen: false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteUser();
          break;
        case "nonactive":
          this.nonActiv(this.state.selectedUsrActive);
        default:
          break;
      }
    }
  }

  getUserList = () => {
    let outer = this;
    this.setState({
      loadingTrigger: true
    });
    axios.post('bo/int/master/cust/list', outer.state.page).then(({ data }) => {
      this.setState({
        loadingTrigger: false
      });
      if (data.sta && data.stacod === 200) {
        data.data.list.forEach((item) => {
          item.birthdatestr = moment(item.birthdate).format("DD-MM-YYYY");
        })
        outer.setState({
          userList: data.data.list,
          userListTotal: data.data.t
        });
      } else {
        outer.showDialog('Warning', data.msg, "")
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  openUserDetail = (row) => {
    // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function (key) {
      let name = '';
      let seq = 0;
      let view = true;
      switch (key) {
        case 'email':
          name = "Email";
          seq = 1;
          view = true;
          break;
        case 'jalaemail':
          name = <IntlMessages id="master.user.jalaemail" />;
          seq = 2;
          view = true;
          break;
        case 'firstname':
          name = <IntlMessages id="master.user.firstname" />;
          seq = 3;
          view = true;
          break;
        case 'lastname':
          name = <IntlMessages id="master.user.lastname" />;
          seq = 4;
          view = true;
          break;
        case 'gender':
          name = <IntlMessages id="master.user.gender" />;
          seq = 5;
          view = true;
          break;
        case 'birthdatestr':
          name = <IntlMessages id="master.user.birthdate" />;
          seq = 6;
          view = true;
          break;
        case 'married':
          name = <IntlMessages id="master.user.married" />;
          seq = 7;
          view = true;
          break;
        case 'child':
          name = <IntlMessages id="master.user.child" />;
          seq = 8;
          view = true;
          break;
        case 'lokal':
          name = "Lokal";
          seq = 9;
          view = true;
          break;
        case 'hall':
          name = "Hall";
          seq = 10;
          view = true;
          break;
        case 'address1':
          name = <IntlMessages id="master.user.address1" />;
          seq = 11;
          view = true;
          break;
        case 'address2':
          name = <IntlMessages id="master.user.address2" />;
          seq = 12;
          view = true;
          break;
        case 'province':
          name = <IntlMessages id="master.user.province" />;
          seq = 13;
          view = true;
          break;
        case 'city':
          name = <IntlMessages id="master.user.city" />;
          seq = 14;
          view = true;
          break;
        case 'mobile1':
          name = <IntlMessages id="master.user.mobile1" />;
          seq = 15;
          view = true;
          break;
        case 'mobile2':
          name = <IntlMessages id="master.user.mobile2" />;
          seq = 16;
          view = true;
          break;

        default:
          view = false;
          break;
      }
      // console.log("key", key);
      if (key != 'lokal') {
        let content = { id: key, name: name, value: row[key], seq: seq, view: view }
        detailContent.push(content);
      } else {
        let content = { id: key, name: name, value: row[key].name, seq: seq, view: view }
        detailContent.push(content);
      }
      // console.log(key, row[key]);

    });
    detailContent.sort(compareSequence);
    console.log(detailContent);
    this.setState({
      detailOpen: true,
      detailContent
    });

  }

  editUser = (row) => {
    this.props.onAddParam({ detail: row });
    this.props.history.push({ pathname: "/bo/master/fulltimer/edit", search: "?i=" + row.id, 
    state:{filter: this.state.page,
    selectedLokal: this.state.selectedLocal}, });
  }

  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', state.selectedRows);
    this.setState({ selectedRows: state.selectedRows });
  };

  deleteUser = () => {
    let userLength = this.state.selectedRows.length;
    if (userLength == 0) {
      this.showDialog("Peringatan", "Pilih salah satu item untuk menghapus", "");
    } else {
      this.showDialog("Hapus User", "Anda akan menghapus " + userLength + " user. Apakah anda yakin?", "delete");
    }
  }


  handleDeleteUser = () => {

    const outer = this;
    const { selectedRows } = this.state;
    let deleteUser = [];
    selectedRows.forEach((user) => {
      deleteUser.push(user.id);
    })
    console.log("delete user", deleteUser);

    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/master/cust/delete', { id: deleteUser }
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getUserList();
            outer.showDialog('Information', <IntlMessages id="master.user.success.deleteuser" />, "");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  warningNonActiv = (row) => {
    if (row.active == "Active") {
      this.setState({
        selectedUsrActive: row
      }, () => {
        this.showDialog("Non Aktifkan User", "Anda akan menonaktifkan user. Apakah anda yakin?", "nonactive");
      })
    } else {
      this.nonActiv(row)
    }
  }

  nonActiv = (row) => {

    const outer = this;
    // const {selectedRows} = this.state;
    // let deleteUser = [];
    // selectedRows.forEach((user)=>{
    //   deleteUser.push(user.id);
    // })

    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/master/cust/active', { id: row.id, status: row.active == "Active" ? "Inactive" : "Active" }
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getUserList();
            outer.showDialog('Information', <IntlMessages id="master.user.success.editstatususer" />, "");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page:1,
        keyword: val.target.value
      }
    }, () => {
      outer.getUserList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getUserList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getUserList();
    })
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        page: {
          ...this.state.page,
          page:1,
          lokalid: val.id
        }
      }, () => { outer.getUserList() });
    } else {
      this.setState({
        selectedLocal: {},
        page: {
          ...this.state.page,
          page:1,
          lokalid: "",
        }
      }, () => { outer.getUserList() });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  render() {
    const { classes, container, theme, content, title } = this.props;
    const { userList, locallist, page } = this.state;

    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/master/fulltimer/list'>
              Daftar Fulltimer
            </Link>            
          </Breadcrumbs>
        </div>
        <div className="filter">
          <Autocomplete
            options={locallist}
            onFocus={(e)=>{this.inputChangeLocal('')}}
            getOptionLabel={option => option.name}
            value={this.state.selectedLocal}
            onChange={this.changeLocal}
            onInputChange={(event, val) => this.inputChangeLocal(val)}
            renderInput={
              param => (
                <TextField
                  {...param}
                  id="outlined-basic"
                  // variant="outlined" 
                  label="Lokal"
                  size="small"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )
            }
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              value={this.state.page.keyword}
              size="small"
              style={{ margin: '5px' }}
              onChange={(val) => this.search(val)}
            />
            <IconButton
              color="primary"
              aria-label="detail"
              component="span"
              onClick={() => {
                this.props.onDeselectParam(); this.props.history.push({
                  pathname: "/bo/master/fulltimer/edit", 
                  state:{filter: this.state.page,
                  selectedLokal: this.state.selectedLocal},
                });
              }}
            >
              <Add />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="detail"
              component="span"
              onClick={() => { this.deleteUser() }}
            >
              <Remove />
            </IconButton>
          </div>
        </div>
        {(this.state.mount)&&
        <DataTable
          columns={this.columns}
          data={userList}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid', marginTop: 10 }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>

          }
          selectableRows
          selectableRowsComponent={Checkbox}
          noHeader
          highlightOnHover
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationDefaultPage={page.page}
          paginationServer
          paginationTotalRows={this.state.userListTotal}
          paginationPerPage={this.state.page.count}
          paginationRowsPerPageOptions={[100,250,500]}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          progressPending={this.state.loadingTrigger}
          progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100} />
          }
        />
        }
        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          mode={this.state.dialogMode}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
        <DialogDetail
          open={this.state.detailOpen}
          title={this.state.detailTitle}
          content={this.state.detailContent}
          handleClose={this.handleDetailClose}
          handleOk={this.handleDetailClose}
        />
      </div>
    );
  }
}

DbMasterFulltimer.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbMasterFulltimer)));